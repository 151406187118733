.default-form-wrapper.two-column #innerwrapper {
  @include susy-breakpoint($breakpoint-large-up, $large) {
    width: auto;
  }
}
.default-form-wrapper.two-column {
  .node-webform {
    max-width: 100%;
  }
  ul {
    &.action-links {
      margin-top: 30px;
    }
  }
  div {
    &.tabs {
      display: block;
    }
  }
  @include susy-breakpoint($breakpoint-small, $small) {
    margin-top: 0;
  }
  @include susy-breakpoint($breakpoint-medium, $medium) {
    margin-top: 0;
  }
  @include susy-breakpoint($breakpoint-large-up, $large) {
    h1 {
      float: left;
      max-width: $two-column-body-max-width;
      margin-right: 0;
      padding-top:0;
    }
    .page-title {
      margin-top: 20px;
      width: 100%;
      max-width: $two-column-body-max-width;
      float: left;
      margin-right: 25px;
      overflow: hidden;
    }
  }
  @include susy-breakpoint($breakpoint-small, $small) {
    .page-title {
      max-width: 100%;
      float: none;
      margin-right: 0;
    }
    h1 {
      float: none;
      max-width: 100%;
    }
  }
  @include susy-breakpoint($breakpoint-medium, $medium) {
    .page-title {
      max-width: 100%;
      float: none;
      margin-right: 0;
    }
    h1 {
      float: none;
      max-width: 100%;
    }
  }
  .field.field-name-body {
    margin-right: 35px;
    margin-top: 0;
    float: left;
    margin-bottom: 15px;
    @include susy-breakpoint($breakpoint-large-up, $large) {
      max-width: $two-column-body-max-width;
      width: 100%;
      overflow: hidden;
      clear: both;
    }
    @include susy-breakpoint($breakpoint-small, $small) {
      max-width: 100%;
      float: none;
      width: 100%;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      max-width: 100%;
      float: none;
      width: 100%;
    }
  }
  .webform-client-form {
    float: right;
    padding: 15px;
    max-width: 400px;
    @include susy-breakpoint($breakpoint-small, $small) {
      float: none;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      float: none;
    }
    @include susy-breakpoint($breakpoint-large-up, $large) {
      max-width: 400px;
      width: 100%;
      clear: right;
      overflow: hidden;
      border: solid 2px #074773;
      padding: 15px;
      float: none;
      display: inline-block;
      fieldset {
        padding-left: 15px;
        legend {
          background: none;
          color: #074773;
          max-width: 360px;
          padding: 15px 15px 15px 0;
          font-size: 22px;
          font-family: "DIN", Arial, Helvetica, "Nimbus Sans L", sans-serif;
          font-weight: 600;
        }
      }
      .form-item {
        label, select, input[type="text"], .custom-select span span {
          font-size: 18px;
        }
        label {
          min-width: 140px;
        }
        label.option {
          min-width: 0;
        }
        textarea {
          max-width: 340px;
        }
        .webform-datepicker {
          select {
            margin: 0;
          }
        }
        select {
          width: auto;
          padding-top: 0;
          padding-bottom: 0;
          margin: 30px 0 0 30px;
          max-width: toRem(280px);
        }
      }
      #edit-actions {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }
}


body {
  .eoy-2020 {
      .therm-style5 {
        *, *:before, *:after {
          box-sizing: border-box;
          margin: 0;
        }
      }
      .body-mobile,
      .body-nonmobile,
      .page-title,
      .page-subtitle,
      p.disclaimer {
          text-align: right;
      }
      .page-title {
          h1#eoy-title {
              margin-bottom: 0px;
              font-size: 60px;
              line-height: 50px;
          }
          h2#eoy-subtitle {
              font-size: 40px;
              line-height: 40px;
              font-family: "gtamcompressed", Arial, Helvetica, "Nimbus Sans L", sans-serif;
              color: #231F20;
          }
      }
      .field-name-body.right-content.field-type-text-with-summary .field-items {
          margin-top: 0px !important;
          margin-bottom: 0px !important;
      }
      .field-name-body.right-content.field-type-text-with-summary {
          margin-top: 35px !important;
          padding-top: 15px !important;
          top: 0 !important;
      }
      @media screen and (min-width: 1000px) and (max-width: 1199px) {
          .page-title {
              h1#eoy-title {
                  font-size: 50px;
                  line-height: 50px;
              }
              h2#eoy-subtitle {
                font-size: 34px;
                line-height: 34px;
              }
          }
      }
      @media screen and (max-width: 999px) {
          .field-name-body.right-content.field-type-text-with-summary {
              margin-top: -20px !important;
          }
          .page-title {
              h1#eoy-title {
                  font-size: 40px;
                  line-height: 40px;
              }
              h2#eoy-subtitle {
                font-size: 27px;
                line-height: 27px;
              }
          }
      }
  }
}

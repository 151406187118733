.default-form-wrapper.legacy-wrapper.two-column {
  .webform-client-form {
    background-color: #15497A;
    .webform-component-markup {
      color: #fff;
    }
    .control-group {
      width: auto;
      float: none;
      display: inline-block;
    }
    .description {
      margin-left: 0;
      color: #FFF;
    }
    label,
    #webform-component-form-intro-text,
    #webform-component-form-intro-text p {
      font-weight: bold;
      font-size: 19px;
      margin-bottom: 0px;
      padding: 0;
      font-family: Georgia,"Times New Roman",sans-serif;
    }
    label {
      font-weight: normal;
      width: auto;
      font-size: 16px;
      color: #FFF;
    }
    div.webform-component-textfield:not(#webform-component-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock),
    #webform-component-mail,
    #webform-component-other-comments,
    .textarea-label {
      label {
        position: absolute;
        padding: 5px 0 0 5px;
        color: #414141;
        font-size: 17px;
        font-family: "DIN",Arial,Helvetica,serif;
      }
    }
    #webform-component-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock {
     label {
       padding: 0;
       width: 100%;
     }
    }
    input[type="text"] {
      width: 195px;
      margin: 0;
    }
    .form-select {
      max-width: 195px;
      width: 100%;
    }
    #webform-component-state,
    #webform-component-second-annuity-recipient--secondary-state,
    #webform-component-second-annuity-recipient--secondary-address,
    #webform-component-second-annuity-recipient--sbp-alt-first-name,
    #webform-component-address,
    #webform-component-sbp-first-name {
      margin-right: 10px; /*custom legacy form styling to keep form short*/
    }
    .form-item input.form-text {
      margin: 0;
      padding: 0 0 0 5px;
      font-size: 17px;
      font-family: "DIN",Arial,Helvetica,serif;
      height: 39px;
      line-height: 39px;
    }
    .webform-component-select {
      &.form-item {
        padding-top: 0;
        margin-top: 0;
      }
    }
    .select {
      margin: 0px;
      width: 100%;
      max-width: 195px;
      .custom-select-container {
        margin: 0;
        width: 100%;
        max-width: 195px;        
        select {
          margin: 0;
        }
        .custom-select {
          display: block;
        }
      }
    }
    .webform-datepicker {
      .form-select {
        max-width: 90px;
        width: auto;
      }
      .form-item {
        margin: 0 10px 0 0;
      }
      .select {
        width: 100%;
        max-width: 90px;
        min-height: auto;
        margin-right: 8px;
        .custom-select-container {
           width: 100%;
           display: inline-flex;
           align-content: flex-start;
           max-width: 5rem;
           .custom-select {
             display: block;
           }
        }
        label {
          font-family: "DIN",Arial,Helvetica,serif;
          font-size: 17px;
        }
      }
    }
    #webform-component-phone-type,
    #edit-submitted-phone-number,
    textarea,
    input[type="email"],
    #edit-submitted-mail {
      width: 100%; /*custom legacy form styling set these fields full width*/
      max-width: 100%;
      margin: 0;
    }
    fieldset {
      padding-left: 0;
      padding-right: 0;
      border: none;
      margin-top: 0;
      &.collapsed {
        display: none;
      }
    }
    .webform-component-checkboxes {
      .form-type-checkbox {
        position: relative;
        margin-bottom: 0;
        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          & ~ label:before {
            content: " ";
            background-color: white;
            background-size: 25px 50px;
            top: 3px;
            height: 25px;
            width: 25px;
            border: none;
            background-position: 0px -25px;
            margin-right: 7px;
            position: absolute;
            left: 0px;
          }
          &:checked ~ label:before {
              background-position: 0 0px;
              // inlined version of https://static.aclu.org/images/icon/checkboxes-checked-white-bg.png
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACzSURBVHgB7dWBCcMgEAVQUzqAI+gGjmQ3cARHcSNH0A0cweaEQGsviV6whJAPJyLqQ1Cc8hw2Nq8H+0Nu5IJIjJFJKUtBH00+kBBCFkLAEygF/ZRSPU2TkRpYyjnXjsAmvYBSqv0kWuuyyFp7FMARY8zX4k+IAOAI5/xnE4CIAI5471EIG2sAcGQLIgDryB7UAWwja1AnsI/UEAFoQxYIrjYBKMgELRub+/s9IfKcy7GxiW/xcsRwP4oI1wAAAABJRU5ErkJggg==) no-repeat;
          }
        }
        label {
          margin: 0;
          padding-left: 43px;
          width: 100%;
          padding-top: 0;
          box-sizing: border-box;
          display: inline-block;
        }
      }
     /* unset css in default-form _form-radio-checkboxes.scss to use modern default checkbox styling */
      ~ label:before {
        all: initial;
      }
      &:focus + label:before {
        all: initial;
      }
      &:checked {
        + label:before, &:focus + label:before {
          all: initial;
        }
      }
    }
    #webform-component-date-of-birth-needed-to-determine-your-payment-rate,
    #webform-component-second-annuity-recipient--date-of-birth-of-second-annuity-recipient-optional,
    #webform-component-second-annuity-recipient--date-of-birth-of-second-annuitant {
      input.webform-calendar.hasDatepicker {
        display: none;
      }
    }
    .webform-component-radios #edit-submitted-type-of-gift {
      margin-left: 0px;
      margin-top: 0px;
      .form-item {
        margin-right: 30px;
      }
      input[type="radio"] {
        height: 23px;
        width: 23px;
        accent-color: black;
      }
      .form-item-submitted-type-of-gift {
        padding-left: 0px;
        label {
          padding-top: 4px;
          padding-left: 5px;
        }
      }
    }
    #edit-actions.form-actions {
      padding: 0;
    }
    input#edit-submit {
      margin-left: 0px;
      width: 100%;
      border-bottom: 0px;
      margin-bottom: 17px;
      background: icon-url("bg-action-button.png") repeat-x scroll left top #BB0400;
      border: 0 none;
      color: #FFFFFF;
      cursor: pointer;
      font-family: Arial,Helvetica,sans-serif;
      font-size: 24px;
      height: 48px;
      line-height: 14px;
      margin-top: 20px;
      padding-left: 10px 17px;
      text-align: center;
      border-radius: 3px;
      text-transform: uppercase;
      text-shadow: 2px 2px 0 #990000;
      font-weight: bold;
    }
  }
  #edit-submitted-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock {
    position: absolute;
    padding-left: 20px;
  }
  #webform-component-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock .description {
    margin-top: 5px;
  }
  #dollar-sign {
    z-index: 1;
    padding-left: 5px;
    padding-top: 5px;
    font-family: "DIN",Arial,Helvetica,serif;
  }
  #amount-for-illustration-input-container {
    display: flex;
  }
}

/* CSS Down arrow no fill */

.chevron-wrapper {
  line-height: 32px;
  padding-top: 22px;
  cursor: pointer;
}

.chevron {
  position: relative;
  display: block;
  height: 16px;
  /*height should be double border*/
  &:before, &:after {
    position: absolute;
    display: block;
    content: "";
    border: 8px solid transparent;
    /*adjust size*/
  }
  &:before {
    top: 0;
    border-top-color: #fff;
    /*Chevron Color*/
  }
  &:after {
    top: -3px;
    /*adjust thickness*/
    border-top-color: #094B76;
    /*Match background colour*/
  }
}

/*Change four 'top' values below to rotate (top/right/bottom/left)*/

//
// Initialization partial
//
// To make it easier to use all variables and mixins in any Sass file in this
// theme, each .scss file has a @import "init" declaration. And this _init.scss
// file is in charge of importing all the other partials needed for the theme.
//
// This initialization partial is organized in this way:
// - First we set any shared Sass variables.
// - Next we import Compass plug-ins (Sass mixin libraries).
// - Last we define our custom mixins for this theme.
//

// Function for converting a px based font-size to rem.
@function toRem($size) {
  $remSize: calc($size / 18px);
  // Default font size on html element is 100%, equivalent to toRem(16px);
  @return #{$remSize}rem;
}

//
// Font faces, stacks and sizes.
//

// Compass' vertical_rhythm extension is a powerful tool to set up a vertical
// rhythm for your entire page. You can see some of its mixins and functions in
// use in the normalize.scss file.
// @see http://compass-style.org/reference/compass/typography/vertical_rhythm/

$base-font-size:   18px; // The font size set on the root html element.
$base-line-height: 28px; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 1.89    * $base-font-size;
$h2-font-size: 1.22  * $base-font-size;
$h3-font-size: 1    * $base-font-size;
$h4-font-size: 1    * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$times:           Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$georgia:         Georgia, "Times New Roman", "DejaVu Serif", serif;

$verdana:         Verdana, Tahoma, "DejaVu Sans", sans-serif;
$tahoma:          Tahoma, Verdana, "DejaVu Sans", sans-serif;
$helvetica:       Helvetica, Arial, "Nimbus Sans L", sans-serif;
$arial:           Arial, Helvetica, "Nimbus Sans L", sans-serif;

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         "Courier New", "DejaVu Sans Mono", monospace, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $georgia; // The font family set on the html element.
$font-body:        $georgia;
$font-monospace:   $courier;

$DIN: 'DIN', Arial, Helvetica, "Nimbus Sans L", sans-serif;

%DIN-regular {
  font-family: $DIN;
  font-weight: 400;
}
%DIN-black {
  font-family: $DIN;
  font-weight: 700;
}

// GTAMCOND = GT America Condensed
$GTAMCOND: 'gtamcond', Arial, Helvetica, "Nimbus Sans L", sans-serif;

%GTAMCOND-regular {
  font-family: $GTAMCOND;
  font-weight: 400;
}
%GTAMCOND-black {
  font-family: $GTAMCOND;
  font-weight: 600;
}


// =============================================================================
// Variables
// =============================================================================

// The amount lists, blockquotes and comments are indented.
$indent-amount: 30px;

// Tab styling.
$tabs-container-bg: #fff;
$tabs-border: #bbb;


// =============================================================================
// Partials to be shared with all .scss files.
// =============================================================================

/* Import utility mixins */
@import "media-queries";
@import "retina-sprites";
@import "retina-sprites2";

// Breakpoint
@import "../../node_modules/breakpoint-sass/stylesheets/_breakpoint";

// Grid
@import "grid";

// Colors
@import "colors";

html.js {
  font-size: 112.5%;
}

body.page-node {
  background-color: $charcoal;
}

p, pre {
  margin: 0 0 1.55556em 0;
}

/* default donation submit button placement and size */
.node-type-page .default-form-wrapper #donation-form-column-wrapper,
.node-type-ticketed-event,
.node-type-p2p-donation-form,
.page-fundraiser-offline,
.node-type-donation-form {
    #edit-actions.form-actions {
        padding-left: 28%;
        @include susy-breakpoint($breakpoint-small, $small) {
          padding-left: 0;
       }
    }
    input.form-submit,
    input#edit-submit,
    input#edit-submit.btn,
    #edit-submit.form-submit {
      width: auto;
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      float: none;
    }
    input.form-submit.paypal-default-submit,
    #edit-paypal-submit {
      height: auto;
      border: none;
      display: none;
    }
   /* hide default sb4 payment features we are not using */
   #webform-component-payment-information {
     #webform-component-payment-information--recurs-monthly,
     #webform-component-sbp-salutation,
     #webform-component-sbp-phone,
     #webform-component-payment-information--payment-method {
       display: none;
     }
   }
   /* hide threatmetrix related additions */
   .fluid-width-video-wrapper {
     display: none;
   }
   /* hide threatmetrix related additions */
   #edit-submitted-payment-information-payment-fields-credit img {
     display: none;
   }
}

.field-name-body,
.body-wrapper {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  ul {
    list-style: disc outside none;
    font-family: Georgia,"Times New Roman","DejaVu Serif",serif !important;
    font-size: 100%;
    margin-left: toRem(16px);
    overflow: hidden;
    padding-left: 30px;
  }
  li {
    margin-bottom: toRem(10px);
    font-family: Georgia,"Times New Roman","DejaVu Serif",serif !important;
    line-height: toRem(22px);
    font-size: 100%;
  }
}
.node-type-donation-form {
  .field-name-body .field-name-field-aclu-right-column, .body-wrapper .field-name-field-aclu-right-column {
    float: right;
    display: block;
    max-width: 100% !important;
  }
  .node-donation-form .content .field-name-field-aclu-summary {
    display: none;
  }
  .webform-component-fieldset.fieldset-step {
    padding: 0;
  }
}

.default-form-wrapper {
  p, pre {
    margin: 0 0 1.55556em 0;
  }
  textarea, input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  .uneditable-input,
  .braintree-hosted-field {
    border: 1px solid #938b82;
    border-radius: 0px;
    height: auto;
  }
  .error input,
  .error select,
  .error textarea {
    border: 2px solid #cc0000;
  }
  .field-name-body  {
    @include susy-breakpoint($breakpoint-wide, $wide) {
      max-width: 100%;
      width: 880px;
    }
  }
  .webform-component-checkboxes > label + div {
    display: inline-block;
    margin: 15px 0 0 10px;
    @include susy-breakpoint($breakpoint-small, $small) {
      margin: 0;
    }
  }
  hr {
    border: 0 none;
    height: 1px;
    color: #ccc; /*cross browser*/
    background-color: #ccc;
    margin: 10px 0 0 0;
  }
  h2 {
    margin-top: 0;
  }
  .node-webform {
    max-width: 90%;
  }
  fieldset {
    border: 0;
    box-sizing: border-box;
    width: 100%;
    legend {
      width: 100%;
      border: 0;
      font-size: 22px;
      line-height: 22px;
    }
  }
  &.legacy-wrapper fieldset {
    border: 1px #c0c0c0 solid;
    legend {
      width: auto;
    }
  }
  form > .form-item {
    width: 100%;
  }
  .form-item textarea,
  .form-item input[type="text"],
  .form-item input.form-text,
  .form-item .webform-datepicker > .form-item,
  .webform-component-select .select .custom-select-container {
     margin: 30px 0 0 30px;
     @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: auto;
         margin-right: auto;
     }
   }
   .form-item .description,
   .form-item .grippie {
     margin-top: 0;
     margin-left: 30px;
     @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: 0;
     }
   }
   &.wrapper-type-petition {
    .form-item input.form-text {
      margin: 0;
    }
    .webform-component-select .select .custom-select-container {
      margin: 10px 0 0;
    }
   }

  &.multistep-donation {
    #webform-component-donation.donation-buttons {
      #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-amount {
        margin: 0;
      }
      .form-item-submitted-donation-recurring-amount, .form-item-submitted-donation-amount {
        display: inline-block;
        padding-left: 0;
        .form-required {
          display: none;
        }
      }
    }
    .form-item {
      padding: 0;
      label {
        font-weight: 400;
      }
      input.form-text, input[type=text], .custom-select-container {
        margin: 0 0 10px 0;
      }
      .custom-select-container {
        margin: 0;
        select {
          margin-bottom: 0;
        }
      }
    }
    #edit-submitted-extra-fields-payment-options {
      margin: 0;
    }
    .form-wrapper.donation-buttons .form-type-radio {
      padding: 0;
    }
    #webform-component-extra-fields--payment-message {
      display: none;
      p {
        margin: auto;
        width: 80%;
        text-align: center;
      }
    }
    #webform-component-donation.donation-buttons input[type=radio] {
      z-index: 1;
    }
    .webform-component-radios>div {
      margin-left: 0;
    }
  }
  .fundraiser_submit_message {
    display: none !important;
  }
  .metrix-container, .metrix-container-cc {
      display: none;
  }
  .prefix-span-wrapper {
    margin: 0;
    top: 5px;
  }
  #take-action-wrapper {
    .form-item textarea,
    .form-item input[type="text"],
    .form-item input.form-text,
    .form-item .webform-datepicker > .form-item,
    .webform-component-select .select .custom-select-container {
      margin: 0;
    }

    .form-item.webform-component-checkboxes label.option {
      padding-right: 0;
    }
  }
  &.wrapper-type-petition.affiliate-template {
    .form-item.webform-component-checkboxes label.option {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .form-item {
    border: none;
    font-size: 19px;
    padding: 5px 0px 0px 0px;
    font-weight: normal;
    margin: 4px 0;
    input {
      color: #555555;
    }
    label {
      font-size: 19px;
      padding: 15px 15px 0 5px;
      font-weight: bold;
     }
     .container-inline div {
       display: inline-block;
     }
     input.webform-calendar {
       vertical-align: baseline;
     }
     input[type="text"] {
       box-sizing: border-box;
     }
     textarea, .grippie {
       box-sizing: border-box;
       float: none;
       display: inline-block;
       width: 93%; /*offset margin left*/
       @include susy-breakpoint($breakpoint-small, $small) {
         width: 100%;
       }
     }
     .webform-grid {
        margin-left: 30px;
        min-width: 0;
        width: 95%;
        margin-top: 30px;
        margin-bottom: 0;
        th.webform-grid-option {
          padding-right: 0;
        }
        @include susy-breakpoint($breakpoint-small, $small) {
            margin-left: 0;
        }
      }
     &.webform-component-checkboxes {
      label.option {
       font-weight: normal;
       font-size: 19px;
       padding: 5px 0px 0px 20px;
       border: none;
       box-sizing: border-box;

       &[for="edit-submitted-donation-email-opt-in-1"] {
         padding: 0 0 0 40px;
       }
      }
      @include susy-breakpoint($breakpoint-small, $small) {
         margin-left: auto;
         label.option {
           padding-left: 0;
         }
      }
    }

    &.webform-component-radios,
    &.webform-component-webform_time {
      .form-type-radio {
        padding: 5px 0px 0px 20px;
        @include susy-breakpoint($breakpoint-small, $small) {
          padding-left: 0px;
        }
        border: none;
        label {
          font-weight: normal;
          font-size: 19px;
          line-height: 19px;
          padding: 0;
          margin-right: 0;
          bottom: 2px;
          position: relative;
        }
      }
      @include susy-breakpoint($breakpoint-small, $small) {
        margin-left: auto;
        label.option {
          padding-left: 0;
        }
      }
    }
    #webform-component-donation--recurring-amount .form-item {
      display: inline-block;
    }
    .form-checkboxes,
    .webform-component-checkboxes {
      .form-item {
        float: none;
      }
      input[type="checkbox"] {
        /* Match the image dimensions */
        @include susy-breakpoint($breakpoint-small, $small) {
          width: 23px;
          height: 23px;
        }
        + label:before {
          display: inline-block;
          @include susy-breakpoint($breakpoint-small, $small) {
            width: 23px;
            height: 23px;
            margin-right: 0px;
            background: icon-url("checkboxes-blue-bg.png") no-repeat;
            content: " ";
            vertical-align: bottom;
            background-position: 0 -23px;
            float: none;
          }
        }
        &:focus + label:before {
          @include susy-breakpoint($breakpoint-small, $small) {
            background-position: 0 -23px;
            float: none;
          }
        }
        &:checked {
          + label:before, &:focus + label:before {
            background-position: 0 0px;
          }
          @include susy-breakpoint($breakpoint-small, $small) {
            background-position: 0 0px;
            float: none;
          }
        }
      }
    }
  }

  #webform-component-disclaimer p {
    font-family: $georgia;
    font-size: 18px;
  }

  #edit-submitted-payment-information-payment-fields-bank-account {
    padding: 0;
  }

  .other_amount_wrapper,
  #webform-component-donation--other-amount,
  #webform-component-donation--recurring-other-amount {
    .description {
      padding: 0;
        display: none;
        visibility: hidden;
    }
  }
  &.affiliate-template.misc-page {
    fieldset {
      background: none;
      margin-top: 1em;
      border: 1px solid #c0c0c0;
      padding: 0.48889em 0.95556em 0.95556em;
      > div {
        margin-bottom: 0.2rem;
      }
    }
  }

  blockquote {
    font-size: 1rem;
    line-height: 1.5555555556rem;
    margin-top: 0;
    margin-right: 0;
    color: #423a38;
    font-family: "DIN", Arial, Helvetica, "Nimbus Sans L", sans-serif;
    font-weight: 800;
    margin-left: 2.7777777778rem;
    p {
      font-size: 1rem;
      line-height: 1.5555555556rem;
      font-weight: 800;
    }
  }
}

.node-type-sba-regulation-action {
  .container-fluid {
    padding: 0;
  }
  #take-action-wrapper {
    margin: 0;
    border: 0;
    padding: 0;
    > legend {
      display: none;
    }
  }
  a {
    text-decoration: underline;
  }
  #webform-component-sbp-state .select {
    margin-bottom: 0;
  }
}
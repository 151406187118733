.node-type-donation-form .default_form-wrapper.two-column {
  @include susy-breakpoint($breakpoint-large-up, $large) {
    #tout-quote {
      background: #e3f5ff;
      color: #074773;
      height: auto;
      width: 467px;
      margin-top: 25px;
      padding: 15px;
      text-align: left;
      margin-bottom: 25px;
      p {
        color: #074773;
        max-width: 437px;
      }
    }
  }
  @include susy-breakpoint($breakpoint-small, $small) {
    #tout-quote {
     display: none;
    }
  }
  @include susy-breakpoint($breakpoint-medium, $medium) {
    #tout-quote {
      display: none;
    }
  }
  .field.field-name-body {
    .block-fundraiser_goals {
      display: none;
    }
  }
  .webform-client-form {
    @include susy-breakpoint($breakpoint-small, $small) {
      #news-signup-wrapper,
      #two-column-right-header {
        display: none;
      }
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      #news-signup-wrapper,
      #two-column-right-header {
        display: none;
      }
    }
    @include susy-breakpoint($breakpoint-large-up, $large) {
      #news-signup-wrapper {
        display: block;
      }
      #two-column-right-header {
        background: #074773;
        color: #fff;
        height: auto;
        width: 100%;
        font-size: 28px;
        text-transform: uppercase;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 20px;
        font-family: "DIN", Arial, Helvetica, "Nimbus Sans L", sans-serif;
        font-weight: 700;
        display: block;
      }
      .other-form-block {
        display: inline-block;
        width: 100%;
        > div {
          display: inline-block !important;
        }
      }
      fieldset {
        padding-left: 15px;
        legend {
          background: none;
          color: #074773;
          max-width: 360px;
          padding: 15px 15px 15px 0;
          font-size: 22px;
          font-family: "DIN", Arial, Helvetica, "Nimbus Sans L", sans-serif;
          font-weight: 600;
        }
      }
      .form-item {
        .donation-label {
          width: 100%;
        }
        input[type="text"] {
          max-width: toRem(197px);
        }
        select {
          max-width: toRem(180px);
        }
      }
      .form-text {
        width: 197px;
      }
      #webform-component-donation {
        legend {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
      #webform-component-donation--aclu-recurring {
        div.form-item > label.donation-label {
          display: none;
        }
      }
      #webform-component-donation--amount {
        .form-radios {
          width: 100%;
        }
        div.form-item > label.select-amount-label {
          display: none;
        }
      }
      #edit-submitted-donation-other-amount {
        max-width: 5rem;
      }
      #edit-actions {
        margin-bottom: 25px;
        text-align: center;
        &.form-actions {
          padding-left: 0;
        }
      }
      #edit-submitted-donation-aclu-recurring-1-wrapper {
        label {
          min-width: 80px;
        }
      }
      #edit-submitted-donation-aclu-recurring-2-wrapper {
        margin-left: 5px;
        label {
          min-width: 80px;
        }
      }
      .form-checkboxes input[type="checkbox"] {
        + label::before {
          width: 21px;
          height: 22px;
          margin-right: 0px;
          background: icon-url("checkboxes-white-bg.png") no-repeat;
          content: " ";
          vertical-align: bottom;
          background-position: 0 -22px;
        }
        &:focus + label:before {
          background-position: 0 -22px;
        }
        &:checked {
          + label:before, &:focus + label:before {
            background-position: 0 0px;
          }
        }
      }
      #edit-submitted-credit-card-information-expiration-date-card-expiration-month {
        max-width: 5.7rem;
      }
      #edit-submitted-credit-card-information-expiration-date-card-expiration-year {
        max-width: 3.1rem;
      }
      #edit-submitted-credit-card-information-card-cvv {
        width: 85px;
      }
      #webform-component-credit-card-information--profile-may-we-share-your-info,
      #webform-component-credit-card-information--fight-for-freedom {
        max-width: 348px;
        div:first-child label:first-child {
          display: none;
        }
        label.option {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
  @include susy-breakpoint($breakpoint-large-up, $large) {
    h1.page-title {
      float: left;
      max-width: 467px;
      margin-right: 0;
      padding-top:0;
    }
    #recurring-message {
      max-width: 350px;
      .icon {
        display: none;
      }
      .monthly-icon {
        display: table-cell;
        max-width: 350px;
        padding-left: 122px;
      }
    }
    .title-wrapper {
      width: 100%;
      max-width: 467px;
      float: left;
      margin-right: 25px;
      overflow: hidden;
    }
    .therm-wrapper {
      max-width: 376px;
      padding-top: 0;
      display: inline-block;
      overflow: hidden;
      float: left;
      clear: right;
      padding-bottom: 10px;
      padding-left: 1px;
      .block-fundraiser_goals {
        .donation-goal-thermometer {
          margin-bottom: 0;
          max-width: 376px;
          .outer-thermometer {
            border: 1px solid #9edafc;
            height: 28px;
            background: repeating-linear-gradient(
              90deg,
              #fff,
              #fff 46px,
              #9edafc 46px,
              #9edafc 47px
            );
            .inner-thermometer {
              height: 28px;
              background-image: repeating-linear-gradient(90deg,#07659e,#07659e 46px,#9edafc 46px,#9edafc 47px);
              margin: 0;
            }
          }
        }
      }
    }
  }
  @include susy-breakpoint($breakpoint-small, $small) {
    .monthly-icon,
    .therm-wrapper {
      display: none;
    }
    .icon {
      display: table-cell;
    }
    .title-wrapper {
      max-width: 100%;
      float: none;
      margin-right: 0;
    }
    h1.page-title {
      float: none;
      max-width: 100%;
    }
    #edit-actions {
      padding: 0;
    }
    .other-form-block {
      width: auto;
      > div {
        display: inline-block !important;
      }
    }
  }
  @include susy-breakpoint($breakpoint-medium, $medium) {
    .monthly-icon,
    .therm-wrapper{
      display: none;
    }
    .icon {
      display: table-cell;
    }
    .title-wrapper {
      max-width: 100%;
      float: none;
      margin-right: 0;
    }
    h1.page-title {
      float: none;
      max-width: 100%;
    }
    .other-form-block {
      width: auto;
      > div {
        display: inline-block !important;
      }
    }
  }
}

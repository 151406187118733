/* stylelint-disable no-duplicate-selectors  */
/********* Footer ************/

.default-form-wrapper #footer-webform {
  @extend %GTAMCOND-regular;
  background: none repeat scroll 0 0 #423a38;
  color: #fff;
  font-size: toRem(13px);
  line-height: toRem(20px);
  text-align: left;
  margin-top: toRem(53px);
  padding-bottom: 0;
  padding-top: toRem(30px);
  padding-left: 0px;
  padding-right: 0px;
  a:link {
    text-decoration: none;
  }
  a {
    color: #FFF;
  }
  a:hover {
    color: #b8e7ee;
  }
  .inner.width-container {
    @include susy-breakpoint($breakpoint-small, $small) {
      width: $width-container-small;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      width: $width-container-medium;
    }
    @include susy-breakpoint($breakpoint-large, $large) {
      width: $width-container-large;
    }
    @include susy-breakpoint($breakpoint-wide, $wide) {
      width: $width-container-wide;
    }
  }
  .inner-inner {
    padding-left: 0px;
    padding-right: 0px;
  }
  label, h2 {
    color: #FFFFFF;
  }
  .footer-content {
    @include susy-breakpoint($breakpoint-medium, $medium) {
       display: table;
       width: 100%;
       text-align: center;
    }
    @include susy-breakpoint($breakpoint-small, $small) {
       display: table;
       width: 100%;
       text-align: center;
    }
  }
  .top-wrapper {
    @include susy-breakpoint($breakpoint-medium, $medium) {
       margin-bottom: toRem(25px);
       display: table-footer-group;
    }
    @include susy-breakpoint($breakpoint-small, $small) {
       margin-bottom: toRem(25px);
       display: table-footer-group;
    }
  }
  #top-block  {
    padding-left: 0;
    margin-left: 0;
    
    @include susy-breakpoint($breakpoint-medium, $medium) {
       display: table;
       width: 100%;
    }
    @include susy-breakpoint($breakpoint-small, $small) {
       display: table;
       width: 100%;
    }
    .top-block-item {
       list-style: none outside none;
       width: auto;
       display: inline-block;
      @include susy-breakpoint($breakpoint-large, $large) {
        max-width: 560px;
      }
     }
     .social {
         float: right;
         @include susy-breakpoint($breakpoint-medium, $medium) {
             float: none;
             margin-top: toRem(25px);
             display: table-header-group;
          }
         @include susy-breakpoint($breakpoint-small, $small) {
             float: none;
             margin-top: toRem(25px);
             display: table-header-group;
         }
     }
     .info {
         @include susy-breakpoint($breakpoint-medium, $medium) {
             display: table-footer-group;
          }
         @include susy-breakpoint($breakpoint-small, $small) {
             display: table-footer-group;
         }
     }
  }
 #block-footer-logo {
    @include susy-breakpoint($breakpoint-medium, $medium) {
         width: 100%;
         display: block;
     }
     @include susy-breakpoint($breakpoint-small, $small) {
         width: 100%;
         display: block;
      }
  }
  p {
    color: #FFFFFF;
    margin: 0;
  }
  .block {
    margin-bottom: 0;
  }
  .block-menu {
    ul.menu a {
      color: #FFFFFF;
      font-size: toRem(11px);
      line-height: toRem(11px);
      &:hover {
        color: #b8e7ee;
      }
    }
    .menu__item.is-leaf {
      list-style: none outside none;
    }
  }
  .inner-inner {
    overflow: visible;
  }
  > .inner {
    &:after {
      clear: both;
      content: " ";
      display: block;
    }
    margin-left: auto;
    margin-right: auto;
    clear: both;
  }
  .footer-nav {
    font-size: toRem(14px);
  }
  .slogan {
    color: #fff;
    font-size: toRem(22px);
    letter-spacing: toRem(1px);
    line-height: toRem(24px);
    margin: toRem(40px) 0 0 0;
    text-transform: uppercase;
    @include susy-breakpoint($breakpoint-small-medium, $small) {
      color: #fff;
      font-size: toRem(18px);
      line-height: toRem(24px);
    }
  }
  #block-menu-menu-footer-menu ul.menu {
    font-size: toRem(16px);
    letter-spacing: toRem(1px);
    margin: 0 0 toRem(49px);
    padding: 0;
    text-transform: uppercase;
    li {
      display: block;
      margin-bottom: toRem(4px);
      margin-left: toRem(13px);
      margin-right: toRem(13px);
    }
  }
  #block-menu-menu-secondary-footer-menu  {
    margin-bottom: 1px;
    ul.menu {
      font-size: toRem(11px);
      line-height: toRem(11px);
      margin: 0 0 toRem(4px);
      padding: 0;
      text-transform: uppercase;
      text-align: left;
      a {
        font-size: toRem(11px);
        letter-spacing: 1px;
      }
      @include susy-breakpoint($breakpoint-small, $small) {
          margin-bottom: toRem(19px);
          margin-left: auto;
          margin-right: auto;
          width: 80%;
          text-align: center;
      }
      @include susy-breakpoint($breakpoint-medium, $medium) {
          margin: 0 0 toRem(4px);
          width: 100%;
          text-align: center;
      }
      li {
        display: inline-block;
        border-right: 1px solid #FFFFFF;
        margin-bottom: toRem(8px);
        margin-left: 0;
        margin-right: toRem(8px);
        padding-left: 0;
        padding-right: toRem(8px);
        line-height: toRem(8px);
        padding-top: 0;
        @include susy-breakpoint($breakpoint-medium, $medium) {
            border-right: none;
        }
        @include susy-breakpoint($breakpoint-small, $small) {
            border-right: none;
        }
        &.last {
          border-right: medium none;
          margin-right: toRem(8px);
          padding-right: 0;
        }
      }
    }
  }
  .copyright {
    margin-top: 2px;
  }
  .fine-print {
    margin-bottom: 0;
    text-align: left;
    line-height: toRem(20px);
    @include susy-breakpoint($breakpoint-medium, $medium) {
        margin-bottom: toRem(20px);
        text-align: center;
        font-size: toRem(13px);
    }
    @include susy-breakpoint($breakpoint-small, $small) {
        margin-bottom: toRem(20px);
        text-align: center;
    }
    p {
      color: #fff;
      margin-bottom: 0;
    }
  }
  #block-menu-menu-social-links {
    ul.menu {
      margin: 0 0 toRem(35px);
      padding: 0;
      float: right;
      text-align: right;
      @include susy-breakpoint($breakpoint-medium, $medium) {
           float: none;
           text-align: center;
      }
      @include susy-breakpoint($breakpoint-small, $small) {
           float: none;
           text-align: center;
      }
      li {
        display: inline-block;
        margin-right: toRem(22px);
        &.leaf {
          padding: 0;
        }
        &.last {
          @include susy-breakpoint($breakpoint-medium, $medium) {
            margin-right: 0;
          }
          @include susy-breakpoint($breakpoint-small, $small) {
            margin-right: 0;
          }
        }
      }
    }
    #menu-item-facebook {
      @include background-image-custom;
      @include social-link("facebook-48x48.png");
      &:hover {
        @include background-image-custom;
        @include social-link("facebook-hover-48x48.png");
      }
    }
    #menu-item-twitter {
      @include background-image-custom;
      @include social-link("twitter-48x48.png");
      &:hover {
        @include background-image-custom;
        @include social-link("twitter-hover-48x48.png");
      }
    }
    #menu-item-youtube {
      @include background-image-custom;
      @include social-link("youtube-48x48.png");
      &:hover {
        @include background-image-custom;
        @include social-link("youtube-hover-48x48.png");
      }
    }
    #menu-item-rss {
      @include background-image-custom;
      @include social-link("rss-48x48.png");
      &:hover {
        @include background-image-custom;
        @include social-link("rss-hover-48x48.png");
      }
    }
  }
  .charity {
    margin-bottom: toRem(40px);
    margin-right: toRem(45px);
    clear: right;
    float: right;
    @include susy-breakpoint($breakpoint-medium, $medium) {
           float: none;
           margin-right: auto;
    }
    @include susy-breakpoint($breakpoint-small, $small) {
           float: none;
           margin-right: auto;
    }
    a:focus, a:hover {
      opacity: .8;
    }
  }
  .charity-nav {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    a {
      @include background-image-custom;
      background-image: logo-url("logo-charitywatch.png");
      display: inline-block;
      height: 75px;
      vertical-align: middle;
      width: 75px;
      background-size: 100%;
    }
  }
  .bbb-accredited {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    a {
      @include background-image-custom;
      background-image: none, logo-url("logo-bbb-accredited-75x121.png");
      display: block;
      width: 47px;
      height: 75px;
      background-size: 100%;
    }
  }
  .candid-seal {
    display: inline-block;
    vertical-align: middle;
    a {
      @include background-image-custom;
      background-image: logo-url("logo-candid.svg");
      display: block;
      height: 75px;
      width: 75px;
      background-size: 100%;
    }
  }
}

//
// Colors
//

// New ACLU colors as of Sept 2017.
// Old hex values left in for reference.
$blue-dark-mem: #231e5f;  // old: #003c5f
$blue-dark: #231e5f;      // old: #094b76
$blue: #005aaa;           // old: #3a7cc2
$blue-light-mem: #6dcff6; // Sticky email signup background color leave as is
$blue-bright: #92d6e3;    // old: #8ddbff
$blue-light: #b8e7ee;     // old: #bbe9ff
$red: #ef404d;            // old: #f60303
$orange: #ff8a00;         // old: #ff8a00
$gold: #fbaf17;           // old: #fab022
$beige: #ffdf69;          // old: #fddb9b
$charcoal: #423a38;       // old: #373e44

$grey: #dbdcdd;
$white: #ffffff;
$black: #000000;



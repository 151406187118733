@import "../global-default-form/variables";
@import "../utilities/functions";
@import "../global-default-form/fonts";

.accordion { // ul
  list-style: none !important;
  padding: 0 !important;
  margin: 0;
  margin-block-start: 20px;
  margin-block-end: 20px;

  .accordion-item {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 30px;
    background-color: #fcfaf7;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:hover {
      background-color: $white;
    }

    &:focus {
      outline: 4px auto currentColor;
      outline: 4px auto -webkit-focus-ring-color;
    }

    .accordion-header {
      display: flex; 
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      
      h3 {
        cursor: pointer;
        font-family: $aclu-sans-standard;
        color: $offblack;
        font-size: 21px;
        line-height: 1.4;
        font-weight: 700;
        padding: 0;
        margin: 0;
      }

      .accordion-icon {
        height: 27px;
        width: 27px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='24'%20height='24'%20viewBox='0%200%2024%2024'%20fill='none'%3e%3cpath%20d='M12%208V16M8%2012H16M22%2012C22%2017.5228%2017.5228%2022%2012%2022C6.47715%2022%202%2017.5228%202%2012C2%206.47715%206.47715%202%2012%202C17.5228%202%2022%206.47715%2022%2012Z'%20stroke='%230055AA'%20stroke-width='2'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/svg%3e");
        &:hover {
          opacity: 0.8;
        }
        background-repeat: no-repeat;
        background-size: cover;
        min-width: 27px;
        margin-left: 12px;
        background-position: center;
      }
    }

    .accordion-content {
      display: none;
      padding: 16px 32px 0 0;
      p, 
      li {
        font-family: $aclu-sans-standard !important;
        color: $disclaimer-grey;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 20px;
        a {
          font-weight: 700;
          color: $blue;
          font-size: inherit;
        }
        &.disclaimer {
          font-family: $aclu-serif;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    a:hover, 
    a:focus {
      opacity: 0.8;
    }
    .list {
      margin: 20px;
      padding-left: 0;
    }
    .disc {
      margin: 20px;
      list-style: disc;
      padding-left: 20px;
    }

    &.active {
      .accordion-content {
        display: block;
      }
      .accordion-icon {
        background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='24'%20height='24'%20viewBox='0%200%2024%2024'%20fill='none'%3e%3cpath%20d='M8%2012H16M22%2012C22%2017.5228%2017.5228%2022%2012%2022C6.47715%2022%202%2017.5228%202%2012C2%206.47715%206.47715%202%2012%202C17.5228%202%2022%206.47715%2022%2012Z'%20stroke='%230055AA'%20stroke-width='2'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/svg%3e");
      }
    }
  }
}
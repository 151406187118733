/* ===== Errors ====== */

.default-form-wrapper {
  %error-item {
      background: none repeat scroll 0 0 $form-error-bg;
      padding: toRem(2px);
      color: $form-error-color;
  }
  %error-style {
      font-size: toRem(24px);
      margin: 0 0 toRem(16px);
  }
  div, #content div {
    &.messages {
      @extend %error-style;
    }
    &.status {
      @extend %error-style;
    }
    &.warning {
      @extend %error-style;
    }
    &.error {
      @extend %error-item;
    }
  }
  .error {
    &.aclu {
      border: solid 3px;
      margin-bottom: 10px;
    }
    input, select, textarea  {
      @extend %error-item;
      @extend %DIN-regular;
      border: 2px solid $form-error-border-color;
      padding: toRem(25px);
      margin-top: 0;
      width: 80%;
      color: $form-error-color;
      font-size: $form-error-item-size;
      line-height: $form-error-item-size;
      ul {
         padding-bottom: 0;
         margin-bottom: 0;
      }
      li {
        list-style: disc outside none;
        margin-left: toRem(25px);
        margin-top: toRem(3px);
        color: $form-error-color;
      }
      a {
         &:link, &:visited, &:hover {
           font-size: $form-error-item-size;
         }
       }
      &.aclu {
        border: solid 3px;
        margin-bottom: 10px;
      }
    }
    select {
      margin-top: 0;
      padding: 0;
    } 
    .custom-select-container {
      label.error {
        margin-left: 0;
      }
    }
  }
  tr {
    &.error {
      @extend %error-item;
    }
  }
  span {
    &.form-required, &.marker {
      color: $form-error-color;
    }
  }
  label.error.valid {
    display: none!important;
  }
}
p.email-error {
  clear: both;
  font-size: toRem(14px);
}
input.email-error {
  border: 2px solid #cc0000;
}

/* stylelint-disable no-duplicate-selectors  */
.default-form-wrapper.legacy-wrapper {
   &.two-column {
     ul {
       &.tabs.primary {
         clear: both;
       }
     }
     /* datepicker div is at bottom of page because of the two-column. Hide it.*/
     #ui-datepicker-div {
      display: none;
     }
   }
   .status, .warning, .alert, .error {
     background-image: none;
   }
   h2, h1 {
     color: #478ECF;
   }
   .header-webform {
     padding-left: 3%;
     .width-container.nav-container {
       margin-left: 4%;
       width: auto;
       @include susy-breakpoint($breakpoint-medium, $medium) {
         width: auto;
         margin-left: auto;
       }
       @include susy-breakpoint($breakpoint-large, $large) {
         width: auto;
       }
     }
   }
   #header-webform-nav {
    &.legacy {
      display: inline-block;
      margin-left: 3%;
      margin-right: toRem(75px);
      margin-bottom: toRem(10px);
      right: auto;
      float: none;
      width: auto;
      align-self: flex-end;
      li {
        @include susy-breakpoint($breakpoint-small, $small) {
          background-color: transparent;
          -webkit-transform: skew(0deg);
          -moz-transform: skew(0deg);
          -o-transform: skew(0deg);
          transform: skew(0deg);
          float: none;
        }
        @include susy-breakpoint($breakpoint-medium, $medium) {
          background-color: transparent;
          -webkit-transform: skew(0deg);
          -moz-transform: skew(0deg);
          -o-transform: skew(0deg);
          transform: skew(0deg);
          float: none;
        }
        @include susy-breakpoint($breakpoint-large-up, $large) {
          background-color: transparent;
          -webkit-transform: skew(0deg);
          -moz-transform: skew(0deg);
          -o-transform: skew(0deg);
          transform: skew(0deg);
        }
        &.menu-item {
          &:after {
            content: "/";
            color: #fff;
            @include susy-breakpoint($breakpoint-small, $small) {
              content: "";
            }
            @include susy-breakpoint($breakpoint-medium, $medium) {
              content: "";
            }
          }
          a {
            padding: 0 toRem(16px);
            @include susy-breakpoint($breakpoint-small, $small) {
              font-size: 15px;
              width: auto;
            }
            @include susy-breakpoint($breakpoint-medium, $medium) {
              font-size: 15px;
              width: auto;
            }
            color: #b8e7ee;
            font-size: 17px;
            -webkit-transform: skew(0deg);
            -moz-transform: skew(0deg);
            -o-transform: skew(0deg);
            transform: skew(0deg);
          }
          a:hover {
            color: #fbaf17;
          }
        }
        &.menu-item:last-child {
          &:after {
            content: "";
          }
        }
      }
    }
  }
  /* hamburger menu style */
  #header-webform-nav {
     display: block;
     left: auto;
     @include susy-breakpoint($breakpoint-small, $small) {
       &.legacy {
         // This image 404s on Springboard 4
         @include hamburger-menu-ul-style($header-bg-color,$header-bg-color,68px,"mobile-menu-right-arrow-white.png",none);
         align-self: center;
         left: 0;
         right: auto;
         &.hamburger-show {
           display: block;
         }
       }
     }
     @include susy-breakpoint($breakpoint-medium, $medium) {
       &.legacy {
         // This image 404s on Springboard 4
         @include hamburger-menu-ul-style($header-bg-color,$header-bg-color,68px,"mobile-menu-right-arrow-white.png",none);
         align-self: center;
         left: 0;
         right: auto;
         &.hamburger-show {
           display: block;
         }
       }
     }
  }
  .mobile-legacy-menu {
    .chevron {
      margin-top: 5px;
      display: none;
      cursor: pointer;
      &:before,
      &:after {
        border-width: 12px;
      }
      &:after {
        border-top-color: $header-bg-color;
      }
      @include susy-breakpoint($breakpoint-small, $small) {
        display: block;
      }
      @include susy-breakpoint($breakpoint-medium, $medium) {
        display: block;
      }
    }
   }
  .legacy-menu--burger {
    background: transparent icon-url("hamburger-small-white.png") no-repeat 0 0;
    height: 25px;
    width: 25px;
    margin-right: 10px;
    display: none;
    @include susy-breakpoint($breakpoint-small, $small) {
      display: block;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      display: block;
    }
  }
  .field-name-body {
    a {
      color: #00538A;
      font-weight: bold;
    }
  }
  &.two-column {
    #content-footer {
      #block1,
      #block2 {
        margin-right: 95px;
      }
    }
  }
  #content-footer {
    hr {
     border: 1px solid #666;
     box-sizing: content-box;
     height: 0;
     margin: toRem(40px) 0;
    }
    display: inline-block;
    margin-top: 25px;
    #block1,
    #block2,
    #block3 {
      display: block;
      float: left;
      box-shadow: 2px 2px 2px #888;
      width: 280px;
      height: 222px;
      margin-bottom: 10px;
    }
    #block1,
    #block2 {
      margin-right: 20px;
    }
    a.b1,
    a.b2,
    a.b3 {
      font-weight: bold;
      font-size: toRem(18px);
      font-family: Arial,Helvetica,sans-serif;
      text-decoration: none;
      color: #fff;
      height: 222px;
      position: absolute;
      width: 280px;
    }
    a.b1 {
      background: icon-url("bequests_by_will.jpg");
    }
    a.b2 {
      background: icon-url("bequests_retirement.jpg");
    }
    a.b3 {
      background: icon-url("charitable_gift.jpg");
    }
    a.b1 .b-text {
      padding: 27px 20px 0 20px;
      position: absolute;
    }
    a.b2 .b-text {
      padding: 12px 20px 0 20px;
      position: absolute;
      line-height: toRem(20px);
    }
    a.b3 .b-text {
      padding: 27px 20px 0 20px;
      position: absolute;
      line-height: toRem(20px);
    }
  }

  .webform-grid-option label.element-invisible {
    // Each webform grid radio button had their own label
    // repeating the question. These were hidden using the
    // element-invisible class, but they were still taking
    // up some width on the page.
    width: 0;
  }

  .node-webform {
    // There's a max-width set on the form that is under 100%, leaving white
    // space on the right side of the grid form fields on mobile. This
    // overrides that value.
    max-width: 100%;
  }

  table.sticky-header {
    // Hides the extra table that SB adds above grid table form fields
    // in order to create a sticky header. This sticky table adds extra
    // width to the page and rarely works as expected, so it is better to
    // remove it.
    display: none;
  }
}

html, .default-form-wrapper {
  // Prevents any page overflow by default. Initially added to prevent
  // horizontal scroll that occurs due to grid element overhang.
  overflow-x: hidden;
}
/* Old style clearfix for Drupal 6 */

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

* html>body .clearfix {
  display: inline;
  width: 100%;
}

* html .clearfix {
  /* Hides from IE-mac \*/
  height: 1%;
  /* End hide from IE-mac */
}

@font-face {
  font-family: 'gtamstandard';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('GT America Standard Regular'), font-url('GT-America-Standard-Regular.woff2') format('woff2'),
    font-url('GT-America-Standard-Regular.woff') format('woff'),
    font-url('GT-America-Standard-Regular.ttf') format('truetype'),
    font-url('GT-America-Standard-Regular.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'gtamstandard';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('GT America Standard Bold'), font-url('GT-America-Standard-Bold.woff2') format('woff2'),
    font-url('GT-America-Standard-Bold.woff') format('woff'),
    font-url('GT-America-Standard-Bold.ttf') format('truetype'),
    font-url('GT-America-Standard-Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'gtamcompressed';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('GT America Compressed Regular'), font-url('GT-America-Compressed-Regular.woff2') format('woff2'),
    font-url('GT-America-Compressed-Regular.woff') format('woff'),
    font-url('GT-America-Compressed-Regular.ttf') format('truetype'),
    font-url('GT-America-Compressed-Regular.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'gtamcompressed';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('GT America Compressed Bold'), font-url('GT-America-Compressed-Bold.woff2') format('woff2'),
    font-url('GT-America-Compressed-Bold.woff') format('woff'),
    font-url('GT-America-Compressed-Bold.ttf') format('truetype'),
    font-url('GT-America-Compressed-Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'gtamextended';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('GT America Extended Bold'), font-url('GT-America-Extended-Bold.woff2') format('woff2'),
    font-url('GT-America-Extended-Bold.woff') format('woff'),
    font-url('GT-America-Extended-Bold.ttf') format('truetype'),
    font-url('GT-America-Extended-Bold.eot') format('embedded-opentype');
}

/* stylelint-disable no-duplicate-selectors, declaration-block-no-duplicate-properties */
/****************** FORM STYLES ******************************/
.default-form-wrapper {
  .webform-container-inline div,
  .webform-container-inline div.form-item {
     display: inline-block;
  }
}
form {
  clear: both;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.braintree-hosted-field {
  border: 1px solid $input-stroke;
  border-radius: 0px;
  height: auto;
}

.node-type-p2p-donation-form,
.node {
  color: $default-form-text-color;
  fieldset {
    background: none;
    margin-top: 1em;
    border: 1px solid #c0c0c0;
    padding: 0.48889em 0.95556em 0.95556em;
    > div {
      margin-bottom: 0.2rem;
    }
   .collapsed {
      overflow: hidden;
   }
   legend {
      text-transform: uppercase;
      background: #bbe9ff;
      font-family: "DIN",Arial,Helvetica,sans-serif;
      font-size: 19px;
      line-height: 19px;
      margin-bottom: 10px;
      padding: 15px;
      color: #373E44;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      span {
        display: inline-block;
      }
      .legend-paren-text {
        font-weight: normal;
        font-family: DINWeb,Arial,Helvetica,sans-serif;
        padding-left: 0;
      }
    }
  }
  .form-item {
    overflow: hidden;
    margin-bottom: 15px;
  }
  input, select, textarea {
    border: 1px solid #999999;
    color: #444444;
  }
  input {
    height: 2rem;
  }
  #webform-component-message {
    margin: 0 0 15px 10px;
    padding: 5px 10px 0 5px;
    width: 100%;
    float: right;
    label, input {
      width: 470px;
      float: none;
    }
    textarea {
      width: 470px;
      float: none;
      height: 155px;
    }
    span.action-title {
      display: block;
      font-weight: bold;
      color: #000000;
      font-size: 13px;
      margin: 0;
      padding: 6px 0;
    }
  }
  .field-prefix {
    @extend %DIN-regular;
  }
}
.help-text {
  @include element-invisible;
}

.hero-item, div.hero-item > img {
  width: 100%;
  height: 100%;
}

.field-name-body .thankyou-hero img[style],
.field-name-body .thankyou-hero img,
.field-name-body img:not(.mytubeplay)[style] {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
}

/*** Custom Select Box Styles **/
/* Select element layout for this example */

.select {
  width: auto;
  max-width: none;
  margin: 3px 0 15px 0;
  display: inline-block;
  z-index: 90;
  height: auto;
  min-height: 2.7rem;
  label {
    float: none;
    z-index: 90;
    &.error.valid {
      display: none!important;
    }
  }
  &.auto-size {
    width: auto;
  }
}


/* Start Custom select element
=========================================================================== */
/* Wrapper element to enable positioning */

.custom-select-container {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 16rem;
  &.auto-size {
    width: auto;
  }
}

/* Set the dimensions of the replaced select element and make it transparent. */

select.custom.replaced {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 27px;
  border: 0;
  filter: alpha(opacity = 0);
  /* Move to IE-specific CSS file if you want to. */
  opacity: 0;
  line-height: 2;
}

.custom-select {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  > span {
    display: block;
    padding: 0 25px 0 6px;
    border: solid 1px #999;
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    background: #fdfdfd icon-url("down_arrow_select_blue.png") no-repeat 100% 50%;
    line-height: 2;
    font-family: $DIN !important;
    pointer-events: none;
  }
  span span {
    display: block;
    height: 2em;
    font-size: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.active > span {
    border-color: #666;
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
}

/* End Custom select element
=========================================================================== */

/** END Custom Select Box Styles ***/

.default-form-wrapper {
  .field-name-body,
  .body-wrapper {
    img[style] {
      width: auto !important;
      height: auto !important;
      max-width: none;
    }
    .node {
      input, select, textarea {
        border: 1px solid #999999;
        color: #444444;
      }
    }
  }
  em.required {
    font-family: DINWeb,Arial,Helvetica,sans-serif;
    font-size: 12px;
    line-height: 17px;
  }
  div.webform-component {
    overflow: hidden;
    clear: both;
  }
  .form-item {
    margin: 4px 0;
    overflow: hidden;
    table {
      width: 100%;
      min-width: 100%;
      width: 1px;
      -webkit-overflow-scrolling: touch;
    }
  }
  div.description {
    margin-right: 10px;
    font-weight: normal;
    font-size: 19px;
    line-height: 1.5em;
    padding: 5px 0 5px 0;
    vertical-align: middle;
    color: $default-form-text-color;
  }
  .webform-component-markup {
    font-size: 19px;
    line-height: 1.5em;
    font-family: "DIN",Arial,Helvetica,"Nimbus Sans L",sans-serif;
  }
  #webform-component-c3-disclaimer.webform-component-markup,
  #webform-component-c4-disclaimer.webform-component-markup {
    font-family: $georgia;
  }
  label {
    margin-right: 10px;
    font-weight: normal;
    font-size: 19px;
    line-height: 1.5em;
    padding: 5px 0 5px 0;
    vertical-align: middle;
    color: $default-form-text-color;
    min-width: 180px;
    margin-bottom: 0px;
    &.option {
      min-width: 0;
    }
    &.error {
      float: none;
      font-size: 16px;
      margin-left: 212px;
    }
  }
  .default-form-wrapper label.option,
  .form-item label.option {
    width: auto;
    margin-right: 10px;
    font-size: 19px;
    line-height: 25px;
    padding: 5px 0 0 0;
    float: none;
    display: table-cell;
  }
  input[type="number"] {
    max-width: 300px;
    width: 100%;
    height: auto;
    padding: 5px;
    font-size: 19px;
    min-height: 2rem;
  }

  #aclu-purpose-node-settings fieldset {
    margin-bottom: 15px;
  }

  #aclu-purpose-node-settings .form-item {
    overflow: hidden;
    input[type="checkbox"] {
      float: left;
      position: relative;
      top: 11px;
      margin-right: 7px;
    }
    .description {
      font-size: toRem(14px);
      color: $charcoal;
    }
  }

  #aclu-purpose-node-settings a {
    text-decoration: underline;
  }

  #aclu-purpose-node-settings input[type="text"] {
    width: 95%;
    max-width: none;
  }

  #aclu-purpose-node-settings input[type="checkbox"] + label {
    display: block;
  }

  #aclu-purpose-node-settings .resizable-textarea textarea {
    float: none;
    display: inline-block;
  }

  button, input {
     &:disabled {
       width: 100%;
     }
  }
  input {
    &:disabled {
      max-width: 250px;
    }
  }

  .form-item {
    input[type="text"],
    input[type="email"] {
      max-width: 16rem;
      width: 100%;
      height: auto;
      padding: 5px;
      font-size: 19px;
      min-height: 2rem;
    }
    textarea, input {
      font-size: 19px;
      line-height: 1.5em;
      // Added important to override other !important tags in cascade
      font-family: "DIN",Arial,Helvetica,serif !important;
      float: none;

      // these have to be separate rules, or the browser
      // will ignore them all
      &:-moz-placeholder {
        @include accessible-placeholder
      }
      &::-moz-placeholder {
        @include accessible-placeholder
      }
      &:-ms-input-placeholder{
        @include accessible-placeholder
      }
      &::-webkit-input-placeholder {
        @include accessible-placeholder
      }
      &::placeholder {
        @include accessible-placeholder
      }
    }
    textarea {
      float: left;
      width: 100%;
      &:disabled {
        width: 100%;
      }
    }
  }

   .form-actions {
      background: none;
      border-top: 0px;
      padding: 0;
      &#edit-actions {
        width: 100%;
        padding: toRem(16px) 0 0 0;
        margin: 0;
      }
      #edit-previous,
      #edit-next {
        margin-bottom: toRem(18px);
      }
    .form-custom-button,
    #edit-submit,
    #edit-previous,
    #edit-next,
    .edit-submit-custom,
    #edit-submit .form-submit {
    background-color: #FF8A00;
    border: 2px solid #FF8A00;
    background-image: none;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;
    letter-spacing: -1px;
    color: #FFFFFF;
    font-size: 21px;
    line-height: 1.25em;
    text-transform: uppercase;
    display: inline-block;
    font-family: DINWeb-Bold,Arial,Helvetica,sans-serif;
    -webkit-appearance: none;
    /*make sure button is square on ipads */
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: auto;
    height: auto;
    clear: both;
    cursor: pointer;
    border-image: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    white-space: normal;
    float: left;
    margin-right: toRem(10px);
   }
   #edit-next,
   #edit-previous {
     float: none;
   }
   #edit-previous {
     margin-right: 5px;
   }
  .form-custom-button:hover,
  #edit-submit:hover,
  #edit-previous:hover,
  #edit-next:hover,
  .edit-submit-custom:hover,
  #edit-submit .form-submit:hover {
    background-color: #094b76;
    border: 2px solid #094b76;
  }
  }
  .form-item select {
    margin-right: 7px;
    width: 100%;
    height: 39px;
    line-height: 25px;
    float: none;
    font-size: 19px;
    padding: toRem(8px) toRem(5px) toRem(8px) toRem(5px);
    font-family: DINWeb,Arial,Helvetica,serif !important;
    box-sizing: content-box;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
  }
  .description {
    padding: 5px 0 0 215px;
    clear: both;
    font-style: normal;
  }
  div.description,
  div.fieldset-description {
    font-family: $DIN;
    font-size: 14px;
    line-height: 17px;
  }
  .form-actions {
    float: none;
    text-align: left;
    overflow: auto;
    overflow: hidden;
    padding: 0px 0 0 212px;
    @include susy-breakpoint($breakpoint-small, $small) {
      padding-left: 0px;
    }
  }
  .submit-description {
    display: none;
  }

  #webform-component-donation--recurs-monthly {
    .form-item {
      display: inline-block;
    }
    label.option {
      min-width: 130px;
    }
  }
  .content ul li a:link,
  .content ol li a:link {
    text-decoration: underline;
  }
}

html.js fieldset.collapsed {
  height: auto;
}
/********** Calendar styles *************/
.ui-datepicker-next label,
.ui-datepicker-prev label {
    background: icon-url("888888_7x7_arrow_left.gif") no-repeat scroll 50% 50% rgba(0, 0, 0, 0);
    border: 1px solid #D3D3D3;
    cursor: arrow;
    min-width: 0;
    line-height:12px;
    display: block;
    font-size: 12px;
    height: 7px;
    text-indent: -999999px;
    width: 7px;
    padding: 5px;
    margin: 0;
}
.webform-datepicker {
  &.webform-container-inline {
    div:nth-of-type(1),
    div:nth-of-type(2),
    div:nth-of-type(3) {
       vertical-align: middle;
       margin:0 5px 0 0;
    }
  }
  .select {
     max-width: toRem(160px);
     width: toRem(160px);
     select {
       max-width: toRem(160px);
       width: toRem(160px);
     }
     .custom-select-container {
       width: toRem(160px);
     }
   }
  .webform-calendar {
    border: none;
    height: auto;
    width: auto;
    input[type="image"] {
      border: none;
      height: auto;
      width: auto;
    }
  }
}
/* @end */

.default-form-wrapper .default-form-section-header {
  text-transform: uppercase;
  @extend %DIN-black;
  font-size: toRem(30px);
  color: $charcoal;
  border-bottom: 2px solid black;
  padding-bottom: 3px;
  margin-bottom: 21px;
  margin-top: 21px;
}

@include susy-breakpoint($breakpoint-small, $small) {
  .default-form-wrapper .default-form-section-header {
    font-size: toRem(24px);
  }
}

/** Confirmation Page Social Share Styling **/
.sb_social_default_style a.social-share-link {
  display: block;
  border: 1px solid #CACACA;
  padding: 20px 0;
  text-decoration: none;
  img {
    width: 40px;
    height: 40px;
  }
  span {
    font-size: toRem(22px);
    font-family: "DIN", Arial, Helvetica, "Nimbus Sans L", sans-serif;
    font-weight: bold;
    color: #373E44;
    padding-left: 7px;
    position: relative;
    top: 4px;
  }
}

.default-form-wrapper.confirmation-page,
.default-form-wrapper .webform-confirmation{
  .aclu-donate-share-simple .aclu-share-social,
  .sb_social_toolbox {
    padding-bottom: toRem(23px);
    overflow: hidden;
  }
  .share-facebook-simple-wrapper,
  .share-twitter-simple-wrapper,
  .share-email-simple-wrapper,
  .sb_social_toolbox p {
    display: inline-block;
    text-align: center;
    font-family: $DIN;
    font-weight: bold;
    color: #373E44;
    width: 32%;
    -ms-transform: 0;
    -webkit-transform: none;
    transform: none;
    background-color: #FFF;
    border: 0;
    float: none;
    @include susy-breakpoint($breakpoint-small, $small) {
      min-width: 280px;
      width: 100%;
      padding-bottom: toRem(20px);
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      width: 30%;
    }
    @include susy-breakpoint($breakpoint-large, $large) {
      width: 30%;
    }
  }
  .sb_social_toolbox p {
    padding-right: 2%;
    margin: 0px;
    float: left;
  }
  .sb_social_toolbox p:last-child {
    padding-right: 0;
  }
  .share-twitter-simple-wrapper,
  .share-email-simple-wrapper {
    padding-left: toRem(10px);
    @include susy-breakpoint($breakpoint-small, $small) {
      padding-left: 0;
    }
  }
  #purpose-share-facebook-simple,
  #purpose-share-twitter-simple,
  #purpose-share-email-simple {
    -ms-transform: 0;
    -webkit-transform: none;
    transform: none;
  }
  .aclu-share-social,
  .sb_social_toolbox {
    padding-top: toRem(23px);
    font-family: $DIN;
    font-weight: 800;
    font-size: toRem(24px);
    text-transform: uppercase;
    @include susy-breakpoint($breakpoint-small, $small) {
      margin-left: 0;
      width: 100%;
    }
  }
}

/** Webform time component **/
.webform-container-inline {
  clear: both;
  height: auto;
  div:nth-of-type(1),
  div:nth-of-type(2),
  #edit-submitted-information-time-minute-wrapper,
  #edit-submitted-information-time-hour-wrapper {
    vertical-align: middle;
  }
  div:nth-of-type(2),
  #edit-submitted-information-time-minute-wrapper {
    margin-left: 5px;
  }
  .form-radios {
    div:nth-of-type(1),
    div:nth-of-type(2) {
      label.option {
        min-width: 0 !important;
      }
    }
  }
}
/* Form processing messaging */
div.processing-spinner {
  position: relative;
  background: rgba(0, 0, 0, 0) icon-url("loader.gif") no-repeat scroll center center;
  height: 16px;
  width: 16px;
  float: left;
  margin: 5px 10px 0 0;
}
div.donation-processing-wrapper,
div.processing-wrapper {
  float: left;
  padding-right: 0px;
}
.webform-user-wrapper {
  clear: both;
}
p.donation_disclaimer {
  clear: both;
  text-align: left;
}
p.donation_disclaimer:empty {
  padding: 0;
}
p.processing-thank-you {
  font-size: 14px;
    margin: 0;
    padding: 0 0 0.5em;
}
p.processing-text {
  font-size: 14px;
    margin: 0;
    padding: 0 0 1em;
    text-align: left;
}
/* stylelint-disable no-duplicate-selectors  */
/* custom radio and checkbox style */
.default-form-wrapper {
   .webform-component-checkboxes, .webform-component-radios, .form-simple-checkbox {
     &.clearfix:after {
        clear: both;
        display: block;
        content: "";
        height:auto;
     }
   }
  .webform-component-radios {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    .form-item.control-group {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .form-item input[type=radio] {
      margin: 0px 5px 2px 2px;
      width: 13px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      @include susy-breakpoint($breakpoint-small, $small) {
        width: 23px;
      }
      height: 23px;
      padding: 0;
      font-size: 19px;
      vertical-align: middle;
      float: left;
      clear: none;
      label {
        float: left;
        clear: none;
      }
    }
  }
  .webform-component-radios > div {
    display: inline-block;
    margin: 15px 0 0 10px;
    @include susy-breakpoint($breakpoint-small, $small) {
      clear: both;
      margin-left: 0;
    }
  }
  .webform-component-checkboxes .description {
    padding: 5px 0 0 215px;
    clear: both;
    font-style: normal;
    font-family: $DIN;
    font-size: 14px;
    line-height: 17px;
    p {
      font-family: $DIN;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .form-simple-checkbox,
  .form-checkboxes,
  .webform-component-checkboxes,
  .fieldset-wrapper,
  #comment-wrapper {
    /* Enable absolute positioning of the hidden form controls */
    position: relative;
    /* Just a bit of space. */
    margin-bottom: 0em;
    /*
    Match line-height to the height of the replacement image to ensure it
    doesn't get clipped
    */
    line-height: 23px;
    label input[type="checkbox"] {
      overflow: visible;
      position: relative;
      width: auto;
      opacity: 1;
    }
    .form-checkboxes label.option,
    .form-simple-checkbox label.option,
    .webform-component-checkboxes label.option {
      float: none;
      width: 100%;
      font-size: 14px;
    }
    input[type="checkbox"] {
      position: absolute;
      display: inline-block;
      /* Match the image dimensions */
      @include susy-breakpoint($breakpoint-small, $small) {
        width: 52px;
        height: 48px;
      }
      width: 23px;
      height: 23px;
      /* Reset anything that could peek out or interfere with dimensions */
      overflow: hidden;
      margin: 0;
      padding: 0;
      border: 1px solid transparent;
      outline: 0;
      opacity: 0;
      + label {
        display: inline-block;
      }
      + label:before {
        display: inline-block;
        position: relative;
        @include susy-breakpoint($breakpoint-small, $small) {
          width: 52px;
          height: 48px;
          background: icon-url("checkboxes-large-blue-bg.png") no-repeat;
          float: left;
          background-position: 0 -47px;
        }
        width: 23px;
        height: 26px;
        margin-right: 0px;
        background: icon-url("checkboxes-blue-bg.png") no-repeat;
        content: " ";
        vertical-align: bottom;
        background-position: 0 -23px;
      }
      &:focus + label:before {
        border: thin #ff8a00 dotted;
        @include susy-breakpoint($breakpoint-small, $small) {
          background-position: 0 -47px;
          float: left;
        }
        background-position: 0 -23px;
      }
      &:checked {
        + label:before, &:focus + label:before {
          background-position: 0 0px;
        }
      }
    }
  }
  #webform-component-payment-information .webform-component-checkboxes label.option,
  #webform-component-payment-information .form-item-springboard-p2p-personal-campaign-action-show-name label.option {
    @include susy-breakpoint($breakpoint-small, $small) {
      font-size: toRem(18px);
    }
    font-size: toRem(14px);
    font-family: $font-stack;
    width: auto;
    float: none;
    display: inline;
  }
  .webform-component-checkboxes .form-item,
  .form-item-springboard-p2p-personal-campaign-action-show-name .form-item {
    margin-bottom: 0
  }
  .tribute-column-wrapper .form-checkboxes,
  .tribute-column-wrapper .webform-component-checkboxes {
    input[type="checkbox"] {
      + label:before {
        @include susy-breakpoint($breakpoint-small, $small) {
          background: icon-url("checkboxes-large-white-bg.png") no-repeat;
          background-position: 0 -47px;
        }
        background: icon-url("checkboxes-white-bg.png") no-repeat;
        background-position: 0 -23px;
      }
      &:focus + label:before {
        @include susy-breakpoint($breakpoint-small, $small) {
          background-position: 0 -47px;
          float: left;
        }
        background-position: 0 -23px;
      }
      &:checked + label:before {
        background-position: 0 0px;
      }
    }
  }
  .control-group.success input, .control-group.success select, .control-group.success textarea {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.form-display input[type="radio"],
input[type="radio"] {
  border: none;
}

.default-form-wrapper {
  .form-display .webform-component-radios .form-radios .form-item,
  .webform-component-radios .form-item {
    margin-bottom: 0;
  }
}
.node-type-webform .node {
  input[type=radio] {
    border: none;
  }
  .webform-component-checkboxes .form-checkboxes .form-item,
  .webform-component-checkboxes .form-item {
    margin-bottom: 0;
  }
   .webform-component-radios .form-radios .form-item,
  .webform-component-radios .form-item {
    margin-bottom: 0;
  }
}
.default-form-wrapper .form-display .webform-component-checkboxes .description,
.default-form-wrapper .webform-component-checkboxes .description,
.form-item-springboard-p2p-personal-campaign-action-show-name .description {
  padding-left: 20px;
  p {
    margin: 0 0 15px 0;
  }
}
#webform-component-Identify-ACLU > label {
  max-width: 20px;
  min-width: 20px;
}
// For chrome browsers add fix to stop scroll to label if out of view when checkbox is checked
// Add it to misc form wrapper first where there are often a long list of checkboxes
.default-form-wrapper.misc-page {
  input[type="checkbox"] {
    position: relative;
    display: block;
    margin-left: -9999;
  }
}

.mytube {
  width: auto !important;
  max-width: 100% !important;
  margin-bottom: toRem(18px);
  .mytubetrigger img.mytubethumb {
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    max-width: 100%;
  }
  img {
    display: block;
    // Move the play button into the middle of the thumb
    &.mytubeplay {
      left: 50% !important;
      margin-left: -30px;
      top: 50% !important;
      margin-top: -33px;
    }
  }
}

/* stylelint-disable media-feature-name-no-unknown */
/*
  @import ../../aclu-sass/mixins
  @import "../../aclu-sass/layouts/responsive";
*/
@mixin susy-breakpoint($breakpoint-range, $susy-layout) {
  // Convert breakpoint px values to ems
  @include breakpoint-set('to ems', true);
  @include breakpoint($breakpoint-range) {
    @content;
  }
}

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: inset(50%);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip-path: none;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;
  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

@mixin skew($amount, $amount2: 0) {
  transform: skew($amount, $amount2);
}

@mixin header-menu-item($size, $bgcolor, $width, $skew: none) {
    height: toRem($size);
    line-height: toRem($size);
    -webkit-transform: $skew;
    -ms-transform: $skew;
    transform: $skew;
    background: none repeat scroll 0 0 $bgcolor;
    width: $width;
    text-align: center;
}
@mixin skew-one($side: right, $deg: -20deg, $right: -15px, $left: -15px, $width: 30px, $bg: #fff) {
    position: relative;

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;

        @if $side == right {
            right: $right;
        }
        @else if $side == left {
            left: $left;
        }
        border-right: 2px solid #373e44;
        display: block;
        width: $width;
        background: $bg;
        -webkit-transform: skew($deg);
            -ms-transform: skew($deg);
                transform: skew($deg);
    }
}
@mixin menu-item-one-skew($skew, $bgcolor, $border-color, $height, $width, $position, $skew-left-side: false) {
     background-color: $bgcolor;
     border: none;
     width: $width;
     height: toRem($height);
     line-height: toRem($height);
     display: block;
     position: relative;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
    -moz-transform: none;
    &:#{$position} {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $bgcolor;
    top: 0;
    @if $skew-left-side == true {
      right: 0;
      transform-origin: top right 0;
      border-top: 0;
      border-left: 2px solid $border-color;
      border-bottom: 2px solid $border-color;
    } @else {
      left: 0;
      border-top: 0;
      transform-origin: bottom left;
      border-right: 2px solid $border-color;
      border-bottom: 2px solid $border-color;
    }
    -ms-transform: $skew;
    -webkit-transform: $skew;
    transform: $skew;
    -moz-transform: $skew;
    }
}
@mixin social-link($social-icon, $inline-image: false, $height: 48px, $width: 48px) {
   @if $inline-image == true {
     background-image: none, url($social-icon);
   } @else {
     background-image: none, icon-url($social-icon);
   }
   height: $height;
   width: $width;
   display: block;
}
@mixin background-image-custom {
  overflow: hidden;
  text-align: left;
  text-indent: -119988px;
  text-transform: capitalize;
  vertical-align: middle;
  background-repeat: no-repeat;
}
@mixin responsive-image-force {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
}
@mixin square-radiobutton($bgcolor, $checked-color-bg, $border-color, $padding: 4px 12px, $margin: 5px, $fixed-width: 0) {
  /*
    Hide radio button (the round disc)
    we will use just the label to create pushbutton effect
    Use positioning instead of display none for accessibility (keyboard tabbing).
  */

   border: 0;
   clip-path: inset(50%);
   height: 1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   width: 1px;
   display: inline-block;
   top: auto;

   /*
     Change the look'n'feel of labels (which are adjacent to radiobuttons).
      Add some margin, padding to label
   */
    + label.option {
      display: inline-block;
      margin: $margin;
      padding: $padding;
      height: 75px;
      line-height: 75px;
      min-width: 0;
      background-color: $bgcolor;
      border: 1px solid $border-color;
      cursor: pointer;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      text-align: center;
      @if $fixed-width != 0 {
        width: $fixed-width;
      }
      &.small {
        width: $fixed-width + 5;
        padding-left: 15px;
        padding-right: 15px;
        margin: 5px;
        font-size: 17px;
        font-weight: bold;
      }
      &.medium {
        font-size: 18px;
      }
      &:hover {
        background-color: $checked-color-bg;
        font-weight: bold;
      }
      &.custom-label {
        padding-left: 15px;
        padding-right: 15px;
        min-width: $fixed-width;
        width: auto;
      }
    }
    &:focus + .button-label,
    &:focus + label.option {
       outline: 1px dotted;
       outline-offset: 3px;
    }
    /*
      Change background color for label next to checked radio button
      to make it look like highlighted button
    */

    &:checked + .button-label,
    &:checked + label {
      background-image: none;
      background-color: $checked-color-bg;
      font-weight: bold;
    }
    &:checked + .button-label {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
    }
    &:checked + label .button-label {
       font-weight: bold;
    }
    + label .button-label::after {
      display:block;
      content:attr(rel);
      font-weight:bold;
      height:1px;
      color:transparent;
      overflow:hidden;
      visibility:hidden;
      margin-bottom:-1px;
    }
    + label .button-label {
      display: block;
      text-align: center;
      &.recurring-simple {
          display: none;
      }
    }
}

@mixin full-bleed-breakout {
  padding-left: 9999px;
  padding-right: 9999px;
  margin-left: -9999px;
  margin-right: -9999px;
}

@mixin resolution-breakpoint {
   /* Temp hack to apply paypal targeting high resolution devices. */
   /* Apply hi res media queries when parent has tablet or ipad class */
   @media
    (min-resolution: 2dppx),
    (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (orientation: landscape) and (min-resolution: 2dppx),
    (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2),
    (orientation: landscape) and (min--moz-device-pixel-ratio: 2),
    (orientation: landscape) and (min-resolution: 192dpi) {
      .tablet &,
      .ipad & {
        @content;
      }
    }
}

@mixin payment-options-large-buttons {
  /*
    Re-skin payment option radio buttons
    Make them large and square and easier to select on touch-screen/non-desktop devices
  */
  #webform-component-donation--amount > .form-item label:first-child,
  #webform-component-donation--recurring-amount > .form-item label:first-child,
    .donation-label-display {
      width: 100%;
      display: block;
      &::after {
        content: ":";
      }
      font-size: 25px;
    }
    legend {
      display: none;
    }
    .form-item {
        .form-type-radio.form-item {
           display: inline-block;
           label {
             display: block;
           }
     }
    }
    #webform-component-extra-fields--payment-options,
    #webform-component-donation--recurs-monthly {
      .psuedo-radio-label,
      input[type=radio] {
        @include square-radiobutton(#fff, #ffcc33, #333, 0px, 5px 20px 5px 0, 270px);
      }
      .psuedo-radio-label + label,
      input[type=radio] + label {
        color: #373e44;
        font-size: 22px;
      }
    }
    #webform-component-extra-fields--payment-options {
      .paypal-pay {
        font-style: italic;
        color: #003366;
      }
      .paypal-pal {
        font-style: italic;
        color: #336699;
      }
      .psuedo-radio-label + label,
      input[type=radio] + label {
        font-size: 19px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    #webform-component-donation--recurs-monthly {
      margin-bottom: 0;
      overflow: hidden;
      + div.form-item {
        margin-bottom: 0;
      }
    }
    #edit-submitted-donation-other-amount {
      width: 250px;
      float: left;
    }
    #webform-component-donation--amount,
    #webform-component-donation--recurring-amount {
      &:focus {
        outline: 0px transparent;
      }
      .other_amount_wrapper,
      #webform-component-donation--other-amount,
      #webform-component-donation--recurring-other-amount {
        display: inline-block;
        margin-top: 3px 0 0;
        clear: both;
        vertical-align: top;
        &.form-item {
          display: none;
        }
        .prefix-span-wrapper {
           margin: 18px 0 0;
           .field-prefix {
             display: none;
           }
           .prefix-text {
               padding-right: 20px;
               font-size: 22px;
               font-weight: 600;
               display: none;
               @include susy-breakpoint($breakpoint-large, $large) {
                 display: none;
               }
               @include susy-breakpoint($breakpoint-medium, $medium) {
                 display: block;
               }
               @include susy-breakpoint($breakpoint-small, $small) {
                 display: block;
               }
           }
        }
        input[type="text"],
        input[type="number"],
        #webform-component-donation--other-amount,
        #webform-component-donation--recurring-other-amount {
          &:-moz-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder,
          &::-webkit-input-placeholder {
            color: #414141;
            opacity: 1;
          }
          float: left;
          width: 250px;
          clear: right;
          @include susy-breakpoint($breakpoint-small, $small) {
            width: 80%;
            max-width: 250px;
          }
          .form-item {
            overflow: visible;
          }
        }
      }
      .psuedo-radio-label,
      input[type=radio] {
        @include square-radiobutton(#fff, #ffcc33, #333, 0px, 10px 20px 10px 0, 90px);
      }
      .psuedo-radio-label + label,
      input[type=radio] + label {
        color: #373e44;
        font-size: 22px;
        float: left;
      }
    }
}
@mixin hamburger-menu-ul-style ($bgcolor: #fff, $border: #373E44, $top: 95px, $right-arrow: "mobile-menu-right-arrow-black.png",$display: none) {
    background: $bgcolor;
    border: 1px solid $border;
    float: none;
    margin: 0;
    position: absolute;
    top: toRem($top);
    z-index: 15;
    display: $display;
    li {
      line-height: 1em;
      border-right: 0;
      display: block;
      padding: 0;
      a:link {
        background: transparent image-url($right-arrow) no-repeat 96% 20px;
        border-bottom: 1px solid #fff;
        display: block;
        font-weight: normal;
        padding: 10px 30px 10px 20px;
        line-height: 1.667rem;
       }
     }
}
@mixin p2p-outline {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(82, 168, 236, 0.8), 0 0 8px rgba(82, 168, 236, 0.8)
}

// These mixins were originally used in the multistep donation form
// but will probably be used more in the future.

// Style1 Global
@mixin button-style1-general {
  @extend %GTAM-bold;
  text-align: center;
  border-radius: 0px;
  font-size: 16px;
  line-height: 19px;
  box-sizing: border-box;
}

// Select/toggle buttons (ie block radio buttons)
@mixin button-select-toggle-unchecked {
  @include button-style1-general;
  background-color: $style1-contrast-color;
  color: $style1-main-color;
  border: 2px solid $style1-main-color;
}

@mixin button-select-toggle-checked {
  background-color: $style1-main-color;
  color: $style1-contrast-color;
  border-color: $style1-main-color;
}

@mixin button-select-toggle-hover {
  background-color: $style1-aux-color;
  color: $style1-contrast-color;
  border-color: $style1-aux-color;
}

// Style 2 Global
@mixin button-style2-general {
  @extend %GTAM-bold;
  font-size: 16px;
  border-radius: 3px;
  padding: 0;
  text-transform: none;
  text-align: center;
  border: none;
}

// Style2 Primary
@mixin button-style2-primary {
  @include button-style2-general;
  color: $style2-contrast-color;
  background-color: $style2-button-color;
}
@mixin button-style2-primary-hover {
  background-color: $style2-aux-color;
}

// Style2 Secondary
@mixin button-style2-secondary {
  @include button-style2-general;
  color: $style2-button-color;
  background-color: $style2-contrast-color;
  border: 2px solid $style2-button-color;
  line-height: 40px;
}
@mixin button-style2-secondary-hover {
  color: $style2-aux-color;
  border: 2px solid $style2-aux-color;
}

// Multistep-specific button styles
@mixin multistep-amount-type-button-size {
  width: 100%;
  height: 50px;
  line-height: 46px;
  padding: 0;
  margin: 0;
  display: inline-block;
}

@mixin multistep-previous-next-button-size {
  width: 100%;
  height: 44px;
  line-height: 44px;
  margin: 0 0 $vertical-button-spacing 0;
  &:last-child {
    margin-bottom: 0px;
  }
  display: inline-block;
}

// Generic placeholder style with sufficient contrast
@mixin accessible-placeholder {
  color: #595959;
  opacity: 1; // Firefox
}

// Multistep placeholder style
@mixin multistep-placeholder {
  color: #595959;
  font-family: $GTAMCOND;
  font-size: 16px;
  font-weight: bold;
  opacity: 1; // Firefox
  line-height: -moz-block-height; // Firefox
}

// side blocks
@mixin side-block {
  border: 2px solid $deemphasized-color;
  box-sizing: border-box;
  padding: 25px;
  overflow: hidden;
  margin-bottom: 0px;
}

// multistep header
@mixin multistep-header {
  @extend %GTAMCOMP-bold;
  color: $style1-header-text-color;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 47px;
}

@mixin disclaimer-style1-text {
  font-size: 15px;
  line-height: 20px;
}

/* stylelint-disable no-duplicate-selectors  */
.header-webform {
  background-color: $header-bg-color;
  width: 100%;
  height: toRem(80px);  
  .logo {	
    @include background-image-custom;	
    background-image: image-url("logo/ACLUCentennialLogo_RGB_white.png");	
    background-position: 50% 50%;	
    background-repeat: no-repeat;	
    display: block;	
    height: 41px;	
    width: 171px;	
    background-size: 171px;	
  }
  .nav-container {
     display: block;
     height: toRem(81px);
     &.width-container {
       @include susy-breakpoint($breakpoint-small, $small) {
         width: 100%;
       }
     }
   }
  .menu-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    @include susy-breakpoint($breakpoint-small, $small) {
      width: 90%;
      margin: auto;
    }
    @include susy-breakpoint($breakpoint-large, $large) {
      width: $width-container-inner-large;
    }
    @include susy-breakpoint($breakpoint-wide, $wide) {
      width: $width-container-large;
    }
    float: none;
  }
  .logo-nav {
    display: inline-block;
    clear: both;
    margin: 0;
    li {
      float: left;
      position: relative;
      z-index: 498;
    }
  }
  ul {
    list-style: none outside none;
    padding: 0;
  }
  ol {
    list-style: none outside none;
  }
  a:link {
    text-decoration: none;
  }
}

.header-webform {
  #header-webform-nav {
    @extend %GTAMCOND-regular;
    display: inline-block;
    clear: both;
    padding: 0px;
    position: relative;
    bottom: auto;
    margin: 0;
    list-style: none outside none;
    -webkit-align-self: center;
    align-self: center;
     @include susy-breakpoint($breakpoint-small-medium, $medium) {
       margin: 0;
     }
     li {
      float: left;
      position: relative;
      z-index: 498;
      margin-left: 0;
      a:hover {
        color: $header-hover-color;
      }
      a {
        @extend %GTAMCOND-regular;
        color: $header-color;
        font-size: toRem(18px);
        display: inline-block;
        text-transform: uppercase;
        @include skew(20deg,0deg);
        padding: 0;
      }
    }
    a {
      position: relative;
    }
    .menu-item-act, .menu-item-donate {
      @include header-menu-item(30px,$header-bg-color,auto,skew(-20deg,0deg));
      padding: 0;
      a {
         padding: 0 toRem(18px);
      }
    }
    .menu-item-donate a {
      padding-right: 0;
    }
    .menu-item-act {
      &:after {
        content: "/";
         color: #fff;
         float: right;
         padding: 0;
         font-size: toRem(18px);
         transform: skew(20deg, 0deg);
         @include susy-breakpoint($breakpoint-medium, $medium) {
           color: #fff;
         }
      }
     }
    }
}


.header-webform {
  #block-search-form {
    @extend %GTAMCOND-regular;
    text-align: center;
    background-color: $header-bg-color;
    display: block;
    cursor: pointer;
    a.search-link {
      display: inline-block;
      padding: toRem(8px);
    }
    form {
       position: static;
    }
    .search-header {
      @extend %GTAMCOND-regular;
      margin: auto;
      width: auto;
      color: $header-color;
      span {
        background-image: icon-url("search-magnify-header.png");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: block;
        height: 15px;
        margin: 0 auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        text-align: left;
        text-indent: -119988px;
        text-transform: capitalize;
        width: 14px;
      }
    }
  }
}

/* stylelint-disable property-no-unknown  */
/* iFrame Overlay Styles */

/* For styles that should only apply when the form isn't being iframed */
body:not(.embed-form) {
  .overlay-container {
    display: none;
  }
}

body.main-site.default-form-wrapper.embed-form {
  background-color: transparent;
  padding: 0;
  margin: 0 auto 26px;
  min-width: 290px;
  overflow: hidden;
}

.default-form-wrapper.embed-form {
  max-width: 708px;

  #wrapper {
    padding-bottom: 0px;
  }

  .width-container,
   #innerwrapper,
  .field-name-body,
  .body-wrapper {
    max-width: 708px;
  }

  &.node-type-donation-form {
    fieldset > div {
      max-width: 708px;
    }
    #webform-component-donation--other-amount.webform-component {
      margin-right: 0;
    }
    #webform-component-donation.donation-buttons #webform-component-donation--amount > .form-item label:first-child {
      display: none;
    }
    #webform-component-donation.donation-buttons {
      margin-top: 0px;
      #webform-component-donation--amount input[type=radio]:hover + label,
      #webform-component-donation--amount input[type=radio]:checked + label,
      #webform-component-donation--aclu-recurring input[type=radio]:hover + label,
      #webform-component-donation--aclu-recurring input[type=radio]:checked + label,
      #payment-options input[type=radio]:hover + label,
      #payment-options input[type=radio]:checked + label {
        background-color: #f71c1c;
        color: white;
      }
      #webform-component-donation--aclu-recurring {
        margin-top: 30px;
      }
      #recurring-message .message-wrapper {
        display: none;
      }
    }
  }

  .width-container {
    width: 100%;
  }

  .field-name-body,
  .body-wrapper,
  div.content {
    margin-top: 0;
  }

  #wrapper .clear-block {
    background-color: transparent;
  }

  .noniframed-display-only {
    display: none;
  }

  .overlay-container {
    @extend %DIN-regular;
    width: auto;
    height: auto;
    overflow: hidden;
    color: white;
    background-image: background-url("lightbox-background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #095587;
  }

  .donation-goal-thermometer .leading-text-thermometer {
    color: #bae8ff;
    text-align: center;
    font-size: toRem(29px);
    font-weight: 500;
    margin-bottom: 12px;
  }

  .block-fundraiser_goals {
    margin-top: 30px;
  }

  .donation-goal-thermometer {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0px;
  }

  .therm-punctuation {
    display: none;
  }

  .donation-goal-thermometer .pointer-number-container-thermometer {
    display: none;
  }

  .messages,
  .webform-client-form {
    @extend %DIN-regular;
    padding-right: 6%;
    padding-left: 6%;
  }

  #content div.messages,
  .webform-component-markup {
    margin-top: 15px;
  }

  #content div.messages.error {
    margin-left: 6%;
    margin-right: 6%;
    width: 88%;
    box-sizing: border-box;
  }

  p.disclaimer {
    @extend %DIN-regular;
    margin-top: 0px;
  }

  .overlay-container:focus {
    outline: none;
  }

  #overlay-header .logo-nav {
    clear: both;
    float: left;
    margin: 0px 0 16px 0;
    position: relative;
    left: 13px;
  }

  #overlay-header .logo-nav .logo {
    background-image: logo-url("lightbox-logo.png");
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    overflow: hidden;
    text-align: left;
    text-indent: -119988px;
    text-transform: capitalize;
    vertical-align: middle;
    width: 116px;
    height: 46px;
  }

  #overlay-headline {
    width: 100%;
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: toRem(53.5px);
    line-height: 53.5px;
    color: white;
    font-weight: 600;
    margin: auto;
  }

  #overlay-header ul {
    line-style: none outside none;
  }

  #call-to-action {
    @include skew(-20deg, 0deg);
    float: right;
    background-repeat: no-repeat;
    background-color: $orange;
    position: relative;
    left: 10px;
    padding: 10px 30px 13px 15px;
    box-shadow: 12px 7px $charcoal;
    margin-left: 6%;
  }

  #call-to-action-text {
    @extend %DIN-black;
    @include skew(20deg, 0deg);
    font-weight: 600;
    font-style: italic;
    font-size: toRem(29px);
    letter-spacing: -0.03em;
    margin-left: 7px;
  }

  #overlay-content {
    padding: 58px 6% 45px;
    font-size: toRem(29px);
  }

  #overlay-text-wrapper {
    text-align: center;
    font-size: toRem(25px);
  }

  #overlay-header {
    @extend %DIN-black;
    color: white;
    height: auto;
    margin-top: 13px;
  }

  @media only screen and (max-width: 475px) {
    #overlay-text-wrapper {
      font-size: toRem(21px);
    }

    .donation-goal-thermometer .leading-text-thermometer {
      font-size: toRem(22px);
    }

    #overlay-content {
      padding-top: 30px;
    }

    #call-to-action-text {
      margin-right: 7px;
      font-size: toRem(24px);
    }

    #overlay-header {
      margin-bottom: 0px;
      text-align: center;
    }

    #overlay-header .logo-nav {
      clear: both;
      display: block;
      float: none;
      margin-bottom: 30px;
    }

    #call-to-action {
      float: none;
      height: auto;
      padding-right: 15px;
      padding-left: 15px;
      margin: auto;
      display: inline-block;
      left: 0px;
    }

    #overlay-headline {
      font-size: toRem(39px);
      line-height: toRem(36px);
      text-align: center;
    }

    #webform-component-credit-card-information .cc-info-holder #webform-component-credit-card-information--card-cvv {
      width: auto;
      height: auto;
    }

    .messages,
    .webform-client-form {
      padding-right: 3%;
      padding-left: 3%;
    }

    #content div.messages.error {
      margin-left: 3%;
      margin-right: 3%;
      width: 94%;
      box-sizing: border-box;
    }
  }

  @media only screen and (min-width: 708px) {
    #webform-component-credit-card-information .form-checkboxes label {
      font-size: toRem(14px);
    }

    .form-checkboxes input[type="checkbox"] {
      width: 23px;
      height: 23px;
    }

    #webform-component-donation.donation-buttons #extra-fields-wrapper #email-signup-wrapper .option {
      line-height: 21px;
      font-size: 19px;
    }

    .form-checkboxes input[type="checkbox"] + label:before {
      display: inline-block;
      width: 23px;
      height: 23px;
      margin-right: 0px;
      background: icon-url('checkboxes-blue-bg.png') no-repeat;
      content: " ";
      vertical-align: bottom;
      background-position: 0 -23px;
      float: none;
    }

    .form-checkboxes input[type="checkbox"]:checked + label:before,
    .form-checkboxes input[type="checkbox"]:checked:focus + label:before {
      background-position: 0 0px;
    }
  }
}

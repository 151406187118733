/*
font-url is a custom function defined in _functions.scss
*/
@font-face {
    font-family: 'gtam';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: font-url('GT-America-Standard-Regular.woff2') format('woff2'),
    font-url('GT-America-Standard-Regular.woff') format('woff'),
    font-url('GT-America-Standard-Regular.ttf') format('truetype'),
    font-url('GT-America-Standard-Regular.eot') format('embedded-opentype');
}
@font-face {
    font-family: 'gtam';
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: font-url('GT-America-Standard-Bold.woff2') format('woff2'),
    font-url('GT-America-Standard-Bold.woff') format('woff'),
    font-url('GT-America-Standard-Bold.ttf') format('truetype'),
    font-url('GT-America-Standard-Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'gtamcompressed';
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: local('GT America Compressed Regular'), font-url('GT-America-Compressed-Regular.woff2') format('woff2'),
    font-url('GT-America-Compressed-Regular.woff') format('woff'),
    font-url('GT-America-Compressed-Regular.ttf') format('truetype'),
    font-url('GT-America-Compressed-Regular.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'gtamcompressed';
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: local('GT America Compressed Bold'), font-url('GT-America-Compressed-Bold.woff2') format('woff2'),
    font-url('GT-America-Compressed-Bold.woff') format('woff'),
    font-url('GT-America-Compressed-Bold.ttf') format('truetype'),
    font-url('GT-America-Compressed-Bold.eot') format('embedded-opentype');
}

@font-face {
    font-family: 'gtamcond';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: font-url('GT-America-Condensed-Regular.woff2') format('woff2'),
    font-url('GT-America-Condensed-Regular.woff') format('woff'),
    font-url('GT-America-Condensed-Regular.ttf') format('truetype'),
    font-url('GT-America-Condensed-Regular.eot') format('embedded-opentype');
}
@font-face {
    font-family: 'gtamcond';
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: font-url('GT-America-Condensed-Bold.woff2') format('woff2'),
    font-url('GT-America-Condensed-Bold.woff') format('woff'),
    font-url('GT-America-Condensed-Bold.ttf') format('truetype'),
    font-url('GT-America-Condensed-Bold.eot') format('embedded-opentype');
}

@font-face {
    font-family: 'DINWeb';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: font-url('DINWeb.eot') format('embedded-opentype'),
    font-url('DINWeb.woff') format('woff');
}
@font-face {
    font-family: 'DINWeb-Medium';
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
    src: font-url('DINWeb-Medium.eot') format('embedded-opentype'),
    font-url('DINWeb-Medium.woff') format('woff');
}
@font-face {
    font-family: 'DINWeb-Bold';
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: font-url('DINWeb-Bold.eot') format('embedded-opentype'),
    font-url('DINWeb-Bold.woff') format('woff');
}
@font-face {
    font-family: 'DINWeb-Black';
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
    src: font-url('DINWeb-Black.eot') format('embedded-opentype'),
    font-url('DINWeb-Black.woff') format('woff');
}

@font-face {
    font-family: 'Lavos Handy';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: font-url('lavoshandy_99-webfont.eot') format('embedded-opentype'),
    font-url('lavoshandy_99-webfont.woff') format('woff');
}
@font-face {
    font-family: 'lavoshandyregular';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: font-url('LavosHandy_99.ttf') format('truetype');
}

.captcha.form-wrapper {

  .fieldset-description {
    padding-bottom: 10px;
  }

  // These styles are for forms where we try to keep the field label
  // and field on the same line on desktop/tablet.
  .one-line-desktop {
    .captcha-extra-info {
      width: 100%;
      max-width: 16rem;
      display: flex;
      flex-direction: column;
      #edit-captcha-response {
        margin-bottom: 0px;
      }
      .description {
        font-size: 14px;
      }
      .error {
        margin-left: 0;
        order: 1;
      }
    }
  }
}

/* stylelint-disable no-duplicate-selectors  */
/* =============== Node Level Styles =============== */

body {
   background-color: #423a38;
   &.page-node,
   &.main-site {
    background-image: none;
    min-height: 100%;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  #skip-link a {
    color: white;
    font-weight: bold;
  }
  a {
    color: #007AB8;
  }
  // Accessibility
  .screenreader-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &.appear-on-focus:focus {
      position: static;
      overflow: visible;
      width: auto;
    }
  }
}

.default-form-wrapper, #container {
  background-image: none;
  background-color: #fff;
}

.default-form-wrapper {
  .conversion {
    overflow: hidden;
  }
  #wrapper {
    background-image: none;
    min-height: 100%;
    padding-bottom: 26px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    .clear-block {
      background-color: #fff;
    }
  }
  #innercontent {
    margin-right: 0px;
  }
  #innerwrapper {
    @include susy-breakpoint($breakpoint-large, $large) {
      width: $width-container-inner-large;
    }
    @include susy-breakpoint($breakpoint-wide, $wide) {
      width: $width-container-large;
    }
    margin-right: 0px;
    float: none;
  }
  .width-container {
    margin: auto;
    @include susy-breakpoint($breakpoint-small, $small) {
      width: $width-container-small;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      width: $width-container-medium;
    }
    @include susy-breakpoint($breakpoint-large, $large) {
      width: $width-container-large;
    }
    @include susy-breakpoint($breakpoint-wide, $wide) {
      width: $width-container-wide;
    }
  }
  #container {
    margin: 0 auto;
    width: 100%;
    left: 0;
    background-color: #fff;
  }
  p.disclaimer {
    font-size: toRem(14px);
    color: #373e44;
    margin: toRem(15px) 0px 0px 0px;
    line-height: toRem(15px);
  }
  .conversion {
    overflow: hidden;
  }
  /* note: should be able to remove this style I think it's been replaced by donation_disclaimer */
  p.disclaimer_florida {
    font-size: toRem(12px);
    color: #666;
    font-family: DINWeb,Arial,Helvetica,sans-serif;
    padding: toRem(50px) toRem(40px) 0 0;
    text-transform: uppercase;
    line-height: toRem(14px);
    a {
      &:link, &:visited, &:hover {
        text-decoration: none;
        color: #666;
      }
    }
  }
  .form-required-info {
    font-size: toRem(12px);
    line-height: toRem(18px);
    padding-top: 4px;
  }
  .page-title,
  .field-name-body,
  .body-wrapper  {
    @include susy-breakpoint($breakpoint-wide, $wide) {
      max-width: $donation-form-width-wide;
      width: $donation-form-width-wide;
    }
   }
  .page-title,
  .field-name-body,
  .body-wrapper,
   div.content {
    @include susy-breakpoint($breakpoint-large, $large) {
      max-width: $donation-form-width-large;
      width: 100%;
    }
    @include susy-breakpoint($breakpoint-small-medium, $medium) {
      max-width: $donation-form-width-medium;
      width: 100%;
    }
  }
  .field.field-name-body {
    margin-bottom: 0;
  }
  .field-name-body.right-content {
     @include susy-breakpoint($breakpoint-wide, $wide) {
       max-width: $donation-form-width-wide;
       width: $donation-form-width-wide;
     }
   }
  .field-name-body.right-content {
     p {
       @include susy-breakpoint($breakpoint-wide, $wide) {
         max-width: $donation-form-width-wide;
       }
     }
   }
  .field-name-body,
  .body-wrapper, div.content {
    margin: 0;
    padding: 0;
    font-family: $georgia;
    font-size: $base-font-size;
    margin-top: 20px;
  }
  &.sidebar-inner-right #main-content > .node {
    width: 100%;
    float: none;
    position: relative;
    overflow: visible;
  }
  #content {
    width: 100%;
    float: none;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    line-height: $base-line-height;
    #main-content {
      p {
        margin-bottom: 20px;
      }
      ul {
        margin-left: 0;
      }
      ul.tabs .primary {
        li {
          a {
            &:link, &:visited, &:hover {
              font-weight: bold;
            }
            &:link, &:visited {
              color: gray;
            }
            &:hover {
              text-decoration: underline;
            }
            &.active {
              color: black;
            }
          }
        }
      }
      ul.action-links {
        li {
          border: solid gray 1px;
          width: auto;
          max-width: 220px;
          padding-left: 10px;
          background-color: #094b76;
          margin-bottom: 10px;
          a {
            &:link, &:visited, &:hover {
              color: white;
              font-size: 16px;
              font-weight: bold;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .content {
    font-size: 1.3em;
  }
  h1.page-title,
  h1 {
    @extend %DIN-black;
    color: $page-title-color;
    font-size: toRem(30px);
    line-height: toRem(30px);
    margin-bottom: 20px;
    margin-top: 0px;
    padding-top: toRem(40px);
    @include susy-breakpoint($breakpoint-small, $small) {
      font-size: toRem(30px);
      line-height: toRem(30px);
      margin-bottom: 12px;
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      margin-bottom: 16px;
    }
  }
  h2.page-subtitle {
    @extend %DIN-regular;
    font-size: toRem(24px);
    line-height: toRem(24px);
    @include susy-breakpoint($breakpoint-small, $small) {
      font-size: toRem(18px);
      line-height: toRem(18px);
    }
    @include susy-breakpoint($breakpoint-medium, $medium) {
      font-size: toRem(24px);
      line-height: toRem(24px);
    }
  }
  p {
    color: #414141;
  }
  li {
    line-height: $base-line-height;
  }

  .form-item.form-item-submitted-time-field-hour {
    margin-right: 5px;
  }
  form {
    margin-bottom: 0;
  }
}

body.pref-center-page .default-form-wrapper {
  .field-name-body {
    width: inherit;
    max-width: inherit;
  }
  iframe[src*=".salesforce-sites.com"] {
    width: 100%;
    height: 1990px;
  }
}

.page-node-done .default-form-wrapper h1 {
  color: $charcoal;
}

// hide 'Go back to the form' link on confirmation pages
.webform-confirmation + .links {
  display: none;
}

// Springboard overrides

[class*="span"] {
  float: none;
  margin-left: 0px;
  display: initial;
  width: auto;
}

.tabs ul.tabs.primary {
  display: flex;
  flex-wrap: wrap;
}
/* stylelint-disable property-no-unknown  */
@include susy-breakpoint($breakpoint-small, $small) {
  .confirmation-page .messages {
    width: 100%;
  }
}
.singlestep-donation {
.webform-confirmation,
.fundraiser-confirmation {
   fieldset {
     div {
      margin-bottom: 0;
     }
     margint-top: 1em;
     border: solid 1px #c0c0c0;
     padding: 0.48889em 0.95556em 0.95556em;
     legend {
       box-sizing: border-box;
       -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
       width: 100%;
       text-transform: uppercase;
       background: #bbe9ff;
       font-family: "DIN",Arial,Helvetica,sans-serif;
       font-size: 19px;
       line-height: 19px;
       margin-bottom: 10px;
       padding: 15px;
       color: #373E44;
     }
   }
   #content-header {
     padding-top: toRem(25px);
     text-align: left;
   }
   #confirmation-header {
      padding-top: toRem(25px);
      text-align: left;
   }
   #confirmation-body {
      background-color:#fff;
      color: #333333;
      font-family: Georgia,"Times New Roman",serif;
      font-size: 16px;
      width: 100% !important;
      h2 {
        font-size: toRem(22px);
        line-height: toRem(25px);
      }
      p {
        padding-right: 8px;
        margin: 8px 0 15px;
        font-size: 16px;
     }
     .sb_social_toolbox {
       padding-top: 0;
       padding-bottom: 0;
       &.sb_social_default_style {
         a.social-share-link {
           padding: 10px 0 !important;
           img {
            width: 24px !important;
            height: 24px !important;
           }
           span, img {
             display: none;
           }
           &:after {
             font-size: toRem(20px) !important;
             font-family: "DIN", Arial, Helvetica, "Nimbus Sans L", sans-serif;
             font-weight: bold;
             position: relative;
             color: #373e44;
             padding-left: 30px;
             background-repeat: no-repeat;
             top: 3px;
           }
           &.twitter {
             &:after {
               content: "TWEET IT";
               background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2347a0d8;%7D.cls-2%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Etwitter%3C/title%3E%3Ccircle class='cls-1' cx='12' cy='12' r='12'/%3E%3Cpath class='cls-2' d='M9.41,17.58a8,8,0,0,0,8.15-8c0-.12,0-.24,0-.36A5.84,5.84,0,0,0,19,7.74a5.85,5.85,0,0,1-1.65.45,2.85,2.85,0,0,0,1.26-1.56,5.85,5.85,0,0,1-1.82.68,2.88,2.88,0,0,0-4.7.77,2.79,2.79,0,0,0-.18,1.8A8.38,8.38,0,0,1,8.63,9,8.16,8.16,0,0,1,6,6.94a2.72,2.72,0,0,0-.38,1.41,2.79,2.79,0,0,0,.33,1.33,3,3,0,0,0,.94,1,3.11,3.11,0,0,1-1.3-.35v0a2.82,2.82,0,0,0,2.3,2.76,2.81,2.81,0,0,1-1.29,0,2.8,2.8,0,0,0,1,1.4,2.85,2.85,0,0,0,1.65.56,5.84,5.84,0,0,1-3.55,1.21c-.24,0-.46,0-.69,0a8.3,8.3,0,0,0,4.39,1.26' transform='translate(0 0)'/%3E%3C/svg%3E");
             }
           }
           &.facebook {
             &:after {
               content: "SHARE IT";
               background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;%7D.cls-2%7Bfill:%233e5c98;%7D.cls-3%7Bclip-path:url(%23clip-path);%7D.cls-4%7Bfill:%23fff;%7D%3C/style%3E%3CclipPath id='clip-path' transform='translate(0 0)'%3E%3Crect class='cls-1' x='1.13' y='2.63' width='20' height='20'/%3E%3C/clipPath%3E%3C/defs%3E%3Ctitle%3Efacebook%3C/title%3E%3Ccircle class='cls-2' cx='12' cy='12' r='12'/%3E%3Cg class='cls-3'%3E%3Cpath class='cls-4' d='M12.62,20.62V13.33h2.5l.37-2.85H12.62V8.67c0-.83.24-1.39,1.44-1.39H15.6V4.74a21,21,0,0,0-2.24-.12A3.47,3.47,0,0,0,9.63,8.39v2.09H7.12v2.85H9.63v7.29Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/svg%3E");
            }
           }
           &.email {
             &:after {
               content: "EMAIL IT";
               background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23094c76;%7D.cls-2%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eemail%3C/title%3E%3Ccircle class='cls-1' cx='12' cy='12' r='12'/%3E%3Cpath class='cls-2' d='M19,17.62H5V6.42H19Zm-12.34-1H17.38l-3.51-3.81-.67.71a1.58,1.58,0,0,1-1.13.48h0a1.56,1.56,0,0,1-1.14-.49l-.67-.73ZM6,8.2v7.64L9.56,12ZM14.55,12l3.51,3.81V8.28ZM6.7,7.46l4.92,5.28a.63.63,0,0,0,.44.18h0a.6.6,0,0,0,.44-.17l5-5.29Z' transform='translate(-0.04 -0.02)'/%3E%3C/svg%3E%0A");
             }
           }
         }
       }
     }
     .element-wrapper {
         width: 100%;
         font-size: 15px;
         line-height:17px;
      }
      .element-label {
        text-transform: uppercase;
        font-weight: bold;
       }
      .element-label,
      .element-value {
            width: 40%;
            margin-bottom: 15px;
            display:inline-block;
            @include susy-breakpoint($breakpoint-small, $small) {
                width: 100%;
            }
      }
     .connect-link {
       font-weight: bold;
       text-decoration: underline;
       color: rgb(0, 84, 136);
     }
     ul.connect-list {
       margin-left: 0px;
       padding-bottom: 0;
       li {
         list-style: disc outside none;
       }
     }
     #connect-header,
     #transaction-header {
         background-color: #CCCCCC;
         padding: 12px 10px 10px;
         font-size: 16px;
         font-weight: bold;
         line-height: 17px;
         text-transform: uppercase;
         width: 100%;
         font-family: DINWeb-Bold,Helvetica,sans-serif;
         box-sizing: border-box;
          margin-bottom: 10px;
          color: #fff;
          @include susy-breakpoint($breakpoint-small, $small) {
                padding: 12px 5px 10px;
                font-size: 14px;
           }
       }
     #connect-header {
         background-color: #bbe9ff;
         color: #373e44;
         font-size: 21px;
     }
     .pre-transaction-header-social-icons {
       margin-bottom: toRem(36px);
       .aclu-share-social {
         padding-top: 0px;
       }
       .share-email-simple-wrapper {
         padding-bottom: 0px;
       }
       @include susy-breakpoint($breakpoint-medium-up, $medium) {
         .share-facebook-simple-wrapper,
         .share-twitter-simple-wrapper,
         .share-email-simple-wrapper {
           float: left;
           padding-right: 2%;
           padding-left: 0;
           width: 32%;
         }
         .share-email-simple-wrapper {
           padding-right: 0;
         }
       }
     }
   }
   .clear-both {
     clear:both;
    }
   .share-rail {
    font-size:14px;
    padding:8px;
    background-color: #DDF;
    width:550px;
    margin-top: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    @include susy-breakpoint($breakpoint-small, $small) {
       width: 100%;
    }
   a {
    padding-right:10px;
    float: left;
   }
   #facebook-text {
    margin-top: 10px;
    }
 }
 .links,
 .messages {
     width: 100%;
   }
}
}
.webform-confirmation {
    line-height: toRem(22px);
    font-size: toRem(18px);
    margin-top: toRem(25px);
    p {
      color: #414141;
      margin: 8px 0 15px 0;
    }
    ul {
      margin-left: 20px;
      padding-bottom: 20px;
      color: #414141;
      font-family: $georgia;
      font-size: $base-font-size;
      li {
        list-style: disc inside none;
        margin: 0 0 0.5em;
      }
    }
    a  {
      color: #005488;
      strong {
        text-decoration: underline;
     }
    h2 {
      @extend %DIN-black;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 20px;
      padding-top: 5px;
     }
   }
}
.webform-confirmation,
.fundraiser-confirmation {
   &.alt-color {
     fieldset legend {
       background-color: #990000;
       color: #fff;
     }
    #confirmation-body .confirmation_header {
      width: 100%;
      max-width: 100%;
    }
    #confirmation-body #transaction-header,
    #confirmation-body #transaction-footer {
      max-width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    div.links {
     width: 100%;
    }
  }
}
.page-node-done {
  .field-field-petition-message {
    display: none;
  }
}
.multistep-donation.confirmation {
  .breadcrumb-nav {
    display:none;
    visibility: hidden;
  }
}

.default-form-wrapper.wrapper-type-petition #content #main-content .wg-goal-autodisappearing,
.default-form-wrapper.wrapper-type-petition #content #main-content .wg-goal {
  padding: 20px 20px 5px;
  .wg-progress {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
  }
}
.wg-goal-autodisappearing,
.wg-goal {
  @extend %DIN-black;
  background-color: #fbc04e;
  padding: 25px 20px;
  margin-bottom: 7px;
  .node-type-springboard-petition .default-form-wrapper .form-layout-container & p {
    margin: 0;
  }
  .twitter-progress,
  .wg-progress {
    background: white!important;
    width: auto;
    max-width: 100%;
    margin: toRem(7px) 0px 0px 0px;
    border: 1px solid white;
    height: 30px !important;
    border-radius: 0 !important;
  }
  .twitter-bar,
  .bar {
    background: #255d83 !important;
    position: relative;
    min-width: 6%;
    max-width: 96%;
    &:after {
      background: #255d83 !important;
      content: "";
      height: 30px;
      position: absolute;
      right: -7px;
      top: 0;
      float: none;
      @include skew(-20deg, 0deg);
      width: 16px;
    }
  }
  .goal-label-needed {
    float: right;
  }
  .twitter-progress {
    border: solid gray 1px !important;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f7f7f7 !important;
    .twitter-bar {
      background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) ! important;
      position: static ! important;
      background-color: #255d83 !important;
    }
  }
}

.wrapper-type-election-watch .goal-block {
  .wg-goal-autodisappearing,
  .wg-goal {
    background-color: #4C90CC;
    span {
      color: #fff;
    }
  }

  .wg-goal-cap, .wg-goal-autodisappearing .wg-progress .bar, .wg-goal .wg-progress .bar {
    background-color: #FF8A00;
  }
}

.node-type-donation-form {
  .twitter-progress {
    background: #73adcf !important;
    width: 50%;
    border: solid 3px #bae8ff !important;
  }
  .wg-goal-autodisappearing,
  .wg-goal {
    line-height: 18px;
    padding: 0px;
    background-color: transparent;
    .wg-progress {
      width: 50%;
      border: solid 3px #bae8ff;
      background: repeating-linear-gradient(90deg,#73adcf,#73adcf 10.9%,#bae8ff 10.9%,#bae8ff 11.2%) !important;
      padding-top: 5px;
      padding-bottom: 5px;
      .bar {
        background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #F86D19), color-stop(100%, #F01A1C)) !important;
        background-image: -moz-linear-gradient(left, #f86d19, #f01a1c) !important;
        background-image: -webkit-linear-gradient(left, #F86D19, #F01A1C) !important;
        background-image: linear-gradient(to right, #f86d19, #f01a1c) !important;
        position: static !important;
        max-width: 100% !important;
      }
    }
  }
}

.node-type-donation-form .default-form-wrapper .wg-goal-autodisappearing.therm-style2,
.node-type-donation-form .default-form-wrapper .wg-goal.therm-style2 {
  margin: 7px 0px;
  font-size: 18px;
  @extend %GTAM-bold;
  &,
  p {
    color: $style1-header-text-color;
  }
  .light {
    font-weight: normal;
  }
  .wg-progress {
    width: 100%;
    border: none;
    background: $deemphasized-color !important;
    padding: 0px;
    margin-top: 0px;
    border-radius: 10px !important;
    height: 20px !important;
    margin-bottom: 14px;
    .bar {
      background: #691B40 !important;
      min-width: auto;
      &:after {
        content: none;
      }
    }
  }
}

.wg-goal-autodisappearing,
.wg-goal {
  @extend %DIN-black;
  background-color: #fbc04e;
  padding: 25px 20px;
  margin-bottom: 7px;
  .node-type-springboard-petition .default-form-wrapper .form-layout-container & p {
    margin: 0;
  }
  .twitter-progress,
  .wg-progress {
    background: white!important;
    width: auto;
    max-width: 100%;
    margin: toRem(7px) 0px 0px 0px;
    border: 1px solid white;
    height: 30px !important;
    border-radius: 0 !important;
  }
  .twitter-bar,
  .bar {
    background: #255d83 !important;
    position: relative;
    min-width: 6%;
    max-width: 96%;
    &:after {
      background: #255d83 !important;
      content: "";
      height: 30px;
      position: absolute;
      right: -7px;
      top: 0;
      float: none;
      @include skew(-20deg, 0deg);
      width: 16px;
    }
  }
  .goal-label-needed {
    float: right;
  }
  .twitter-progress {
    border: solid gray 1px !important;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f7f7f7 !important;
    .twitter-bar {
      background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) ! important;
      position: static ! important;
      background-color: #255d83 !important;
    }
  }
}

.wrapper-type-election-watch .goal-block {
  .wg-goal-autodisappearing,
  .wg-goal {
    background-color: #4C90CC;
    span {
      color: #fff;
    }
  }
  .wg-goal-cap, .wg-goal .wg-progress .bar, .wg-goal-autodisappearing .wg-progress .bar {
    background-color: #FF8A00;
  }
}

.node-type-donation-form {
  .twitter-progress {
    background: #73adcf !important;
    width: 50%;
    border: solid 3px #bae8ff !important;
  }
  .wg-goal-autodisappearing,
  .wg-goal {
    line-height: 18px;
    padding: 0px;
    background-color: transparent;
    .wg-progress {
      width: 50%;
      border: solid 3px #bae8ff;
      background: repeating-linear-gradient(90deg,#73adcf,#73adcf 10.9%,#bae8ff 10.9%,#bae8ff 11.2%) !important;
      padding-top: 5px;
      padding-bottom: 5px;
      .bar {
        background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #F86D19), color-stop(100%, #F01A1C)) !important;
        background-image: -moz-linear-gradient(left, #f86d19, #f01a1c) !important;
        background-image: -webkit-linear-gradient(left, #F86D19, #F01A1C) !important;
        background-image: linear-gradient(to right, #f86d19, #f01a1c) !important;
        position: static !important;
        max-width: 100% !important;
      }
    }
  }
}

.node-type-donation-form .default-form-wrapper .wg-goal-autodisappearing.therm-style2,
.node-type-donation-form .default-form-wrapper .wg-goal.therm-style2 {
  margin: 7px 0px;
  font-size: 18px;
  @extend %GTAM-bold;
  &,
  p {
    color: $style1-header-text-color;
  }
  .light {
    font-weight: normal;
  }
  .wg-progress {
    width: 100%;
    border: none;
    background: $deemphasized-color !important;
    padding: 0px;
    margin-top: 0px;
    border-radius: 10px !important;
    height: 20px !important;
    margin-bottom: 14px;
    .bar {
      background: #691B40 !important;
      min-width: auto;
      &:after {
        content: none;
      }
    }
  }
}

@import "functions";
//
// Grid settings, breakpoints - copied from Drupal aclu-sass directory _grid.scss
//

// Establish grid settings - used for backwards compatibility for susy-breakpoint call. custom susy-breakpoint call doesn't need this
$small: (
  math: fluid,
  columns: 6,
  column-width: 44px,
  gutters:  calc(20px / 44px),
);

$medium: (
  math: static,
  columns: 12,
  column-width: 44px,
  gutters: calc(20px / 44px),
);

$large: (
  math: static,
  columns: 12,
  column-width: 55px,
  gutters: calc(20px / 55px),
);

$wide: (
  math: static,
  columns: 16,
  column-width: 55px,
  gutters: calc(20px / 55px),
);

// Breakpoint media query values
$breakpoint-small: 0 767px;
$breakpoint-small-up: 0;
$breakpoint-small-landscape: 480px 767px;
$breakpoint-medium: 768px 959px;
$breakpoint-medium-up: 768px;
$breakpoint-small-medium: 0 959px;
$breakpoint-large: 960px 1199px;
$breakpoint-large-up: 960px;
$breakpoint-small-large: 0 1199px;
$breakpoint-wide: 1200px;

// END DRUPAL BREAKPOINTS

$header-bg-color: #423a38;
$header-color: #b8e7ee;
$header-hover-color: #fbaf17;
$page-title-color: #0d4b76;
$header-menu-search-bg: #FDDB9B;
$header-menu-search-color: #373E44;
$header-menu-donate-bg: #FF8A00;
$header-menu-act-bg: #fbaf17;
$red-dark: #990000;
$input-stroke: #938b82;
$default-form-text-color: #414141;
$form-error-color: #cc0000;
$form-error-bg: #fff;
$form-error-border-color: #cc0000;
$form-error-item-size: toRem(18px);

$submit-btn-bg: #ef404d;
$submit-btn-hover-bg: #a80000;

$p2p-white: $form-error-bg;
$p2p-content-bg: #fff;
$p2p-account-link: #bbe9ff;
$p2p-hover: #094b76;
$p2p-campaign: #246cb0;
$p2p-campaign-alt: #2f1c55;
$p2p-gray: #d7d7d7;
$p2p-gray-hover: #bfbfbf;
$p2p-dollar-sign: #aeafb2;
$p2p-input-border-color: #b1b3b6;
$p2p-submit-bg-color: #005aaa;
$p2p-dark-gray: $header-menu-search-color;
$p2p-red: #EB455D;
$p2p-dark-red: #aa0000;
$p2p-blue: #0059A9;
$p2p-dark-blue: #231E5F;
$p2p-light-blue: #b8e7ee;
$p2p-heading-color: $header-bg-color;
$p2p-alt-blue: #005baa;
$p2p-gold: #ffdf69;
$p2p-dark-gold: #fbaf17;
$p2p-alert-bg: #ffde6a;
$p2p-error-border: #f0404d;
$p2p-alert-color: #41392e;

$social-fb: #3460a1;
$social-twitter: #28aae1;
$social-email: #92d6e3;

$therm-border: $header-bg-color;
$therm-bg: $p2p-white;
$therm-progress: #ef404d;

$width-container-small: 90%;
$width-container-medium: toRem(620px);
$width-container-inner-large: toRem(751px);
$width-container-large: toRem(880px);
$width-container-wide: toRem(1030px);
$width-container-extra-wide: toRem(1160px);

$p2p-width-container-small: 100%;
$p2p-width-container-medium: toRem(620px);
$p2p-width-container-inner-large: toRem(751px);
$p2p-width-container-large: toRem(960px);
$p2p-width-container-wide: toRem(1200px);
$p2p-width-container-extra-wide: toRem(1200px);

$donation-form-width-wide: toRem(750px);
$donation-form-width-large: toRem(880px);
$donation-form-width-medium: toRem(880px);

$font-stack: "DIN",Arial,Helvetica,"Nimbus Sans L",sans-serif;
$two-column-body-max-width: 555px;

// GTAM = GT America Standard Regular
$GTAM: 'gtam', Arial, Helvetica, "Nimbus Sans L", sans-serif;
%GTAM-bold {
  font-family: $GTAM;
  font-weight: bold;
}

// GTAMCOND = GT America Condensed
$GTAMCOND: 'gtamcond', Arial, Helvetica, "Nimbus Sans L", sans-serif;
%GTAMCOND-regular {
  font-family: $GTAMCOND;
  font-weight: 400;
}
%GTAMCOND-black {
  font-family: $GTAMCOND;
  font-weight: 600;
}
%GTAMCOND-bold {
  font-family: $GTAMCOND;
  font-weight: bold;
}

// GTAMCOMP = GT America Compressed
$GTAMCOMP: 'gtamcompressed', Arial, Helvetica, "Nimbus Sans L", sans-serif;
%GTAMCOMP-bold {
  font-family: $GTAMCOMP;
  font-weight: bold;
}

$CENTURY: 'New Century Schoolbook', 'Century Schoolbook', 'Century Schoolbook L', Georgia, serif;

%P2PBLUEBUTTON {
  @extend %GTAMCOND-black;
  font-size: toRem(18px);
  background: $p2p-submit-bg-color !important;
  text-transform: uppercase;
  color: $p2p-white;
  display: inline-block;
  line-height: 36px;
  padding: 0;

  &:hover {
    background-color: $p2p-dark-blue !important;
  }
}

%P2PTITLE {
  @extend %GTAMCOND-black;
  background-color: $p2p-campaign;
  // background-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, $p2p-campaign-alt 4px, $p2p-campaign-alt 8px);
  background-image: none;
  color: #fff;
  font-size: toRem(30px);
  line-height: 2rem;
  margin: 0;
  padding: 1rem;
  text-transform: none;
  text-align: left;
  @include susy-breakpoint($breakpoint-small-medium, $medium) {
    font-size: toRem(20px);
    width: 100%;
  }
  @include susy-breakpoint($breakpoint-medium-up, $medium) {
    margin: 0 0 14px;
  }
}

// These colors were originally used in the multistep donation form
// but will probably be used more in the future.

// Style1
$style1-main-color: black;
$style1-aux-color: #666666;
$style1-contrast-color: white;

// Style2
$style2-button-color: black;
$style2-main-color: #0055AA;
$style2-aux-color: #666666;
$style2-contrast-color: white;

// Other
$error-box-main-color: #EF404E;
$error-box-aux-color: #F9DEDE;
$deemphasized-color: #E5E5E5;

$vertical-button-spacing: 15px;
$style1-body-text-color: #333333;
$style1-header-text-color: #231F20;

// Branded
$style3-body-text-color:  #130F54;


// COLORS
$red-AA-compliant: #D93A3F; // WCAG AA compliant w/ white text
$error-box-aux-color: #F9DEDE;

$dark-navy: #130F54;
$aclu-red-amaranth: #EF404E;
